<template>
    <div class="fouls-table">

        <div style="margin-top:20px;">
            <a-row>
                <a-col span="8">
                    <a-button @click="forceWinClick">Завершить игру</a-button>
                </a-col>
            </a-row>
        </div>

        <a-modal v-model:visible="forceWinVisible"
                 title="Завершить игру" width="200px"
                 class="force-winer-modal">
            <template #footer>
            </template>
            <div>Выберите победителя</div>
            <div>
                <a-button @click="forceWinMafiaClick">Мафия</a-button>
            </div>
            
            <div>
                <a-button @click="forceWinPeacefulClick">Мирные</a-button>
            </div>
        </a-modal>
    </div>
</template>


<script lang="js">
    import { defineComponent } from 'vue';
    import { api } from '@/lib/api.js'


    export default defineComponent({
        components: { 
        },
        data() {
            return {
                forceWinVisible: false
            };
        },


        emits: ['onRefresh'],

                 
        methods: {


            checkFinish() {

                api.post("api/Game/CheckFinish",
                    null,
                    () => {
                        this.$emit('onRefresh');
                    },
                    this.showError
                )

            },

            forceWinClick() {
                this.forceWinVisible = true;
            },

            forceWinMafiaClick() {
                this.forceWinVisible = false;
                api.post("api/GameFinish/ForceWinner",
                    {
                        team: 2
                    },
                    () => {
                        this.checkFinish();
                    },
                    this.showError
                );
            },

            forceWinNobodyClick() {
                this.forceWinVisible = false;
                api.post("api/GameFinish/ForceWinner",
                    {
                        team: 0
                    },
                    () => {
                        this.checkFinish();
                    },
                    this.showError
                );
            },

            forceWinPeacefulClick() {
                this.forceWinVisible = false;
                api.post("api/GameFinish/ForceWinner",
                    {
                        team: 1
                    },
                    () => {
                        this.checkFinish();
                    },
                    this.showError
                );
            }
        },
    });
</script>

<style>


    .force-winer-modal .ant-modal-body div{
        width: 100%;
        margin-bottom: 20px;
    }

    .force-winer-modal .ant-modal-body div button{
        width: 100%;
    }

</style>