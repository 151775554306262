<template>
    <div class="main-table">


        <div :class="{'cards_wrapper': true, 'cards_wrapper_transition':cardTransition} "
             :style="{ left: (245+leftPosition) + 'px' }"
             @touchstart="tableMouseDown" @touchmove="tableMouseMove" @touchend="tableMouseUp">
            <div class="game-inner-round"> </div>

            <div v-for="player in currentTablePlayers" :key="player.id" class="angle_wrapper" v-bind:style="{ transform: 'rotate('+calcDeg(player.orderNum)+'deg)' }">
                <div class="card" @click="onPlayerClickEvent(player)" v-bind:style="{ transform:  'rotate(-'+calcDeg(player.orderNum)+'deg)' }">

                    <img v-if="!player.imageId" :class="{'user-game-avatar': true, 'active-user-avatar': isActiveUser(player), 'disabled-user': player.disabled || player.isDead || player.inJail || player.unavailable }" src="../../../public/avatar.jpg" />
                    <img v-if="player.imageId" :class="{'user-game-avatar': true, 'active-user-avatar': isActiveUser(player), 'disabled-user': player.disabled || player.isDead || player.inJail || player.unavailable }" :src="getsrc(player.imageId)">
                    <div class="card_title">{{player.nikName}}</div>
                    <div v-if="gameValue.showRolesSwitch" class="card_role">{{player.playRoleName}}</div>
                    <div class="voice_count">{{player.voiceCounter}}</div>
                    <div class="dead_player" v-if="player.isDead">Погиб</div>
                    <div class="jail_player" v-if="player.inJail">В тюрьме</div>

                </div>
            </div>

        </div>

        <div class="show-roles-switch">
            Показать роли
            <a-switch v-model:checked="gameValue.showRolesSwitch" />
        </div>

        <div :class="{'left_table_button': true, 'left_table_button_disabled':gameValue.currentTable<=0 || tableSlideButtonsDisabled}">
            <LeftCircleOutlined @click="slideLeftTable"/>
        </div>
        <div :class="{'right_table_button': true, 'right_table_button_disabled':gameValue.currentTable>=maxTable || tableSlideButtonsDisabled}">
            <RightCircleOutlined @click="slideRightTable"/> 
        </div>
        

        <div class="table-buttons">
            <a-radio-group v-if="maxTable>0" v-model:value="gameValue.currentTable" button-style="solid">
                <a-radio-button value=0>1 стол</a-radio-button>
                <a-radio-button value=1>2 стол</a-radio-button>
                <a-radio-button v-if="maxTable>1" value=2>3 стол</a-radio-button>
            </a-radio-group>
        </div>

        <div v-if="gameValue.gameStatus==5 || gameValue.gameStatus==6 || gameValue.gameStatus==8" class="show-fouls-button">
            <a-button @click="showFouls=true">Фолы</a-button>
            <WinButton @onRefresh="refreshPlayers" />
        </div>

    </div>

    <a-modal v-model:visible="showFouls" title="Фолы" :footer="false" width="800px">
        <FoulsTable v-model:game="gameValue" @onRefresh="refreshPlayers" />
    </a-modal>
</template>


<script lang="js">
    const  sleep = function (ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    import { defineComponent } from 'vue';
  //  import { api } from '../lib/api.js'
    import dayjs from 'dayjs';
    import FoulsTable from '@/game/modules/FoulsTable.vue';
    import WinButton from '@/game/modules/WinButton.vue';
    import {
        LeftCircleOutlined,
        RightCircleOutlined,
    } from '@ant-design/icons-vue';

    export default defineComponent({
        components: {
            FoulsTable, 
            WinButton,
            LeftCircleOutlined,
            RightCircleOutlined
        },
        data() {
            return {
                posters: null,
                currentposter: null,
                openModal: false,
                password: null,
                showFouls: false,
                leftPosition: 0,
                fisttouch: null,
                cardTransition: false,
                tableSlideButtonsDisabled: false
            };
        },

        props: ['game'],
        emits: ['update:game', 'onPlayerClick', 'onRefresh'],

        computed: {
            gameValue: {
                get() {
                    return this.game
                },
                set(value) {
                    this.$emit('update:game', value)
                }
            },

            currentTablePlayers: {
                get() {
                    var arr = [];
                    var max = this.gameValue.players.length;
                    var currentTable = this.gameValue.currentTable;

                    if (currentTable == null) currentTable = 0;

                    if (this.maxTable != currentTable) max = (currentTable + 1) * 14;

                    for (var i = currentTable * 14; i < max; i++) {
                        var player = this.gameValue.players[i];

                        if (player != null)
                            arr.push(player);
                    }

                    return arr;
                },
                set() {}
            },

            maxTable: {
                get() {
                    if (this.gameValue.players == null) return 0;
                    return (Math.floor((this.gameValue.players.length-1) / 14));
                },
                set() { }
            }
        },




        mounted() {
            this.gameValue.currentTable = 0;

        },


        methods: {

           

            tableMouseDown(x) {
                this.fisttouch = x.touches[0].clientX;
                this.cardTransition = false;
            },

            tableMouseMove(x) {
                this.leftPosition = x.touches[0].clientX - this.fisttouch;
            },


            async slideRightTable() {
                if (this.gameValue.currentTable >= this.maxTable) {
                    this.slidebackTable();
                    return;
                }
                this.tableSlideButtonsVisible = true;
                this.cardTransition = true;
                this.leftPosition = -2000;
                await sleep(500);
                this.cardTransition = false;
                this.leftPosition = 2000;
                this.gameValue.currentTable++;
                await sleep(100);
                this.cardTransition = true;
                this.leftPosition = 0;
                this.tableSlideButtonsVisible = false;
            },

            async slideLeftTable() {
                if (this.gameValue.currentTable <= 0) {
                    this.slidebackTable();
                    return;
                }
                this.tableSlideButtonsVisible = true;
                this.cardTransition = true;
                this.leftPosition = 2000;
                await sleep(500);
                this.cardTransition = false;
                this.leftPosition = -2000;
                this.gameValue.currentTable--;
                await sleep(100);
                this.cardTransition = true;
                this.leftPosition = 0;
                this.tableSlideButtonsVisible = false;
            },

            slidebackTable() {
                this.cardTransition = true;
                this.leftPosition = 0;
            },

            tableMouseUp() {
                if (this.leftPosition < -300) this.slideRightTable();
                else if (this.leftPosition > 300) this.slideLeftTable();
                else this.slidebackTable();
            },



            isActiveUser(rec) {
                if (this.gameValue.activePlayer == null) return false;
                return (rec.id == this.gameValue.activePlayer.id);
            },

            calcDeg(num) {
                return (num-1) * 360/14;

            },

            getsrc(img) {
                
                if (img)
                    return process.env.VUE_APP_BASE_URL + 'api/Image/Thumbnail?imageId=' + img;
                return null;
            }, 

            getdate(dt) {
                if (dt == null) return '';
                return dayjs(dt).format('DD.MM.YYYY');

            },

            onPlayerClickEvent(rec) {
                if (rec.inJail || rec.disabled || rec.isDead || rec.unavailable) return;
                this.$emit('onPlayerClick', rec)
            },

            refreshPlayers() {
               
                this.$emit('onRefresh');
            }


        },
    });
</script>

<style>

    

    .table-buttons {
        position: absolute;
        z-index: 500;
        bottom: 55px;
        left: 220px;
    }

    .show-roles-switch {
        position: absolute;
        z-index: 501;
        bottom: 60px;
        left: 20px;
    }

    .show-fouls-button {
        position: absolute;
        z-index: 501;
        bottom: 55px;
        right: 60px;
        text-align: right;
    }

    .cards_wrapper {
        position: absolute;
        height: 650px;
        width: 650px;
        /*left: 245px;*/
        top: 65px;
        
    }

    .cards_wrapper_transition {
        transition: left 0.5s;
    }

    .game-inner-round {
        border: 10px solid #1a2127;
        border-radius: 50%;
        position: absolute;
        height: 590px;
        width: 590px;
        left: 30px;
        top: 30px;
        box-shadow: 0px 0px 30px #4e6d8b;
        background-color: #252525;
    }

    .angle_wrapper {
        position: absolute;
        left: calc(50% - 50px);
        height: 50%;
        transform-origin: 50% 100%;
    }



    .card {
        position: relative;
        display: inline-flex;
        flex-flow: column nowrap;
        justify-content: start;
        align-items: center;
        text-align: center;
        height: 100px;
        width: 100px;
        transform-origin: 50% 33px;
        color: #979797;
    }



    .card_img {
        width: 80px;
        height: 80px;
        box-sizing: border-box;
        border-radius: 50%;
        border: 3px solid #d5e1ec;
    }

    .card_title, .card_role {
        margin: 0;
        
        white-space: nowrap;
    }

    .card_rating {
        position: absolute;
        top: 0;
        right: 0;
        padding: 4px;
        border-radius: 4px;
        background-color: #abd2ee;
    }


    .user-game-avatar {
        height: 80px;
        width: 80px;
        min-height: 80px;
        min-width: 80px;
        border-radius: 50%;
        border: 3px solid #1a2127;
    }

    .active-user-avatar {
        box-shadow: 0px 0px 30px #9ed4ff;
    }

    .disabled-user {
        filter: brightness(0.3);
    }

    .dead_player {
        position:absolute;
        top: 30px;
    }

    .jail_player {
        position: absolute;
        top: 30px;
    }

    .voice_count {
        position: absolute;
        top: 0px;
        right: 10px;
    }

    .left_table_button {
        position: absolute;
        left: 20px;
        top: 358px;
        font-size: 48px;
        color: gray;
    }

    .left_table_button_disabled {
        display: none;
    }

    .right_table_button {
        position: absolute;
        right: 50px;
        top: 358px;
        font-size: 48px;
        color: gray;
    }

    .right_table_button_disabled {
        display: none;
    }


    .right_table_button {

    }

</style>