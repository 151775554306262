<template>
    <div>
        Ночь {{gameValue.currentTurn}}
    </div>

    <div v-if="currentRole">
        {{currentRole.name}} {{currentRole.action}}
    </div>
    <div v-if="currentRole"  style="margin-top:20px">
        Цель:
        <div v-for="item in targetPlayers" :key="item.id">
            {{item.nikName}}
        </div>
    </div>
    <PlayersTable v-model:game="gameValue" @onPlayerClick="onPlayerClick" @onRefresh="refreshPlayers" />

    <a-button v-if="!selectRoleVisible && !finished && !currentRole" type="primary" @click="wakeupClick"> Разбудить роль</a-button>

    <a-modal v-model:visible="selectRoleVisible" title="Разбудить роль"
             :maskClosable="false" :footer="false">
        <a-row v-for="item in playRoles" :key="item.code" style="margin-top:20px">
            <a-col :span="6"></a-col>
            <a-col :span="6" style="font-size:120%">{{item.name}}</a-col>
            <a-col :span="4">
                <a-button :disabled="!item.enabled" @click="activateRole(item)">Разбудить</a-button>
            </a-col>
        </a-row>

        <a-row style="margin-top:40px; text-align:center">
            <a-col :span="6"></a-col>
            <a-col :span="10">

                <a-button @click="finish">Завершить ночь</a-button>
            </a-col>
        </a-row>
    </a-modal>


    <a-modal v-model:visible="finished" title="Ночь завершена"
             @cancel="nextGameStatus">
        <template #footer>
            <a-button key="submit" type="primary" @click="nextGameStatus">ОК</a-button>
        </template>
        <div v-if="resultPlayers.length==0">Никто не погиб</div>
        <div v-for="result in resultPlayers" :key="result.playerId">{{result.nikName}} - {{result.resultMsg}}</div>
    </a-modal>

    <a-modal v-model:visible="confirmvisible"
             title="Подтверждение"
             ok-text="Да"
             cancel-text="Нет"
             @ok="confirmOk"
             @cancel="confirmCancel">

        <div v-if="currentRole"> {{currentRole.name}} {{currentRole.action}}</div>
        <div v-if="selectedPlayer"> Цель: 
            <div v-for="item in targetPlayers" :key="item.id">
                {{item.nikName}}
            </div>
        </div>

    </a-modal>




</template>


<script lang="js">
    import { defineComponent } from 'vue';
    import { message } from 'ant-design-vue';
    import { api } from '../lib/api.js'
    import dayjs from 'dayjs';
    import PlayersTable from '@/game/modules/PlayersTable.vue'

    export default defineComponent({
        components: {
            PlayersTable
        },
        data() {
            return {
                confirmvisible: false,
                finished: false,
                resultPlayers: null,
                playRoles: null,
                currentRole: null,
                selectedPlayer: null,
                targetPlayers: [],
                mainWindowClosed: false
            };
        },

        props: ['game'],
        emits: ['update:game'],

        computed: {
            gameValue: {
                get() {
                    return this.game
                },
                set(value) {
                    this.$emit('update:game', value)
                }
            },

            selectRoleVisible: {
                get() {
                    return !this.finished && this.currentRole == null && !this.mainWindowClosed;
                },
                set(value) {
                    this.mainWindowClosed = !value;
                }
            },

            showResult: {
                get() {
                    return !this.finished && this.currentRole != null;
                },
                set() { }
            }
        },

        mounted() {
            this.refreshPlayers();
            
        },

        


        methods: {

            wakeupClick() {
                this.mainWindowClosed = false;
            },


            refreshPlayers() {
                //this.gameValue.activePlayer = null;
                //for (var i = 0; i < this.gameValue.players.length; i++)
                //    this.gameValue.players[i].voiced = false;

                api.get("api/GameNight/GetPlayersState",
                    (data) => {
                        
                        this.stateApply(data);
                    },
                    this.showError
                );
            },

            activateRole(role) {
                this.gameValue.blockScreen = true;
                api.post("api/GameNight/ActivateRole",
                    role,
                    (data) => {
                        this.stateApply(data);
                        this.targetPlayers = [];
                    },
                    this.showError
                )
            },


            stateApply(data) {
                this.gameValue.blockScreen = false;
                this.finished = data.isFinished;
                this.resultPlayers = data.resultPlayers;
                this.gameValue.players = data.players;
                this.gameValue.gameStatus = data.gameStatus;
                this.gameValue.winnerTeam = data.winnerTeam;
                this.currentRole = data.currentRole;
                this.playRoles = data.playRoles;

                if (this.playRoles.filter(r => r.enabled).length == 0 && this.currentRole == null && !this.finished) {
                    this.finish();
                }

            },


            finish() {
                this.gameValue.blockScreen = true;
                api.post("api/GameNight/Finish",
                    null,
                    (data) => {
                        this.gameValue.blockScreen = false;
                        this.finished = data.isFinished;
                        this.resultPlayers = data.resultPlayers;
                    },
                    this.showError
                )
            },



            getsrc(img) {
                if (img)
                    return process.env.VUE_APP_BASE_URL + 'api/Image/Thumbnail?imageId=' + img;
                return null;
            },

            getdate(dt) {
                if (dt == null) return '';
                return dayjs(dt).format('DD.MM.YYYY');

            },

           
            onPlayerClick(rec) {
                if (this.currentRole) {
                    var immuinityActions = [0, 4, 5];
                    if (rec.initialImmunity && this.gameValue.currentTurn == 1 && this.currentRole != null && immuinityActions.includes(this.currentRole.actionCode)) {
                        message.error('Иммунитет');
                        return;
                    }
                    this.confirmvisible = true;
                    this.selectedPlayer = rec;

                    if (this.targetPlayers.indexOf(this.selectedPlayer) == -1)
                        this.targetPlayers.push(this.selectedPlayer);

                    while (this.targetPlayers.length > this.currentRole.targetCount)
                        this.targetPlayers.shift();

                    this.confirmvisible = this.targetPlayers.length == this.currentRole.targetCount;
                }

            },

            confirmOk() {
                this.confirmvisible = false;
                this.gameValue.blockScreen = true;

                if (this.targetPlayers.length == this.currentRole.targetCount) {
                    var ids = [];
                    for (var i = 0; i < this.targetPlayers.length; i++)
                        ids.push(this.targetPlayers[i].id);

                    api.post("api/GameNight/ActionRole",
                        {
                            playRoleCode: this.currentRole.code,
                            playerIds: ids
                        },
                        (data) => {
                            this.refreshPlayers()
                            this.targetPlayers = [];
                            message.info(data.message);
                        },
                        this.showError
                    )
                }

            },

            confirmCancel() {
                this.targetPlayers = [];
            },


            nextGameStatus() {
                this.gameValue.blockScreen = true;
                api.post("api/Game/SetNextStatus",
                    null,
                    (data) => { this.gameValue = data; },
                    this.showError
                )
            },


            showError(e) {
                message.error(e);
            },


            finishNightClick() {

            }
        },
    });
</script>


<style>
    .duel_num_button {
        width: 50px;
        height: 50px;
        margin: 10px;
        font-size: 200%;
    }
</style>

