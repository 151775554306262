<template>
    <div>
        Выберите игру
    </div>
    <a-spin v-if="loading" />
    <div v-if="!loading">
    <div v-for="poster in posters" :key="poster.id" class="poster-row">

            <div>
                <img class="poster-image" v-if="!poster.imageId" src="../../public/avatar.jpg" />
                <img class="poster-image" v-if="poster.imageId" :src="getsrc(poster.imageId)">
            </div>
            <h3>{{poster.info}}</h3>
            <div style="padding-left:170px">{{poster.fullInfo}}</div>
            <div style="padding-left: 170px">{{getdate(poster.eventDate)}}</div>
            <div style="margin-top: 20px; padding-left: 170px ">Лимит игроков: {{poster.gamersLimit}}</div>
       
            <a-table class="ant-table-posters"
                     :columns="columns"
                     :data-source="poster.games"
                     :pagination="false"
                     style="margin: 20px 0px">

                <template #bodyCell="{ record, column }">


                    <template v-if="column.key === 'action'">
                        <a-button v-if="!record.finished" @click="continueGame(record.id)" style="width:100px" type="primary">В игру</a-button>
                        <a-button v-if="record.finished" @click="continueGame(record.id)" style="width:100px" >Результат</a-button>
                    </template>

                </template>
            </a-table>

        <div style="text-align:center; margin-bottom:40px" >
            <a-button @click="openCreateGame(poster.id)"  type="primary" style="width:200px; height:40px">Создать игру</a-button>
        </div>
    </div>
    </div>

    <a-modal v-model:visible="openModal" title="Создание игры" @ok="createGame">
        <span>Введите пароль: </span><a-input v-model:value="password" />
        <a-spin v-if="loading" />
        <div style="font-size:80%">Примечание: Не учитываются пробелы в начале и конце пароля, пароль не должен быть пустым </div>
    </a-modal>
</template>


<script lang="js">
    import { defineComponent } from 'vue';
    import { message } from 'ant-design-vue';
    import { api } from '../lib/api.js'
    import dayjs from 'dayjs';

    export default defineComponent({
        components: {
        },
        data() {
            return {
                posters: null,
                currentposter: null,
                openModal: false,
                password: null,

                createLoading: false,
                loading: false
            };
        },

        mounted() {
            this.loading = true;
            api.get('api/SelectPosters/Get', this.gameinfoCallback, this.showError);
        },

        setup() {

            return {
                columns: [
                    {
                        title: 'Игра',
                        dataIndex: 'name',
                        key: 'name',
                    },
                    {
                        title: 'Игроков',
                        dataIndex: 'playersCount',
                        key: 'playersCount',
                    },
                    {
                        title: 'Статус',
                        dataIndex: 'status',
                        key: 'status',
                    },
                    {
                        title: 'Победитель',
                        dataIndex: 'winner',
                        key: 'winner',
                    },

                    {
                        title: '',
                        dataIndex: '',
                        key: 'action',
                        align: 'center'
                    },



                ],

            };
        },


        methods: {

            getsrc(img) {
                if (img)
                    return process.env.VUE_APP_BASE_URL + 'api/Image/Thumbnail?imageId=' + img;
                return null;
            }, 

            getdate(dt) {
                if (dt == null) return '';
                return dayjs(dt).format('DD.MM.YYYY');

            },

            gameinfoCallback(result) {
                this.posters = result;
                this.loading = false;
            },


            openCreateGame(id) {
                this.currentposter = id;
                this.openModal = true;
            },


            createGame() {
                if (this.password == null || this.password.trim() == '') {
                    message.error('Необходимо ввести пароль');
                    return;
                }

                this.createLoading = true;
                var data = {
                    posterId: this.currentposter,
                    password: this.password.trim()
                };

               

                api.post('api/SelectPosters/CreateGame', data, this.createGameCallback, this.showError)
                
            },

            createGameCallback() {
                this.createLoading = false;
                this.openModal = false;
                this.$router.push('/game');
            },


            continueGame(gameId) {
                var data = {
                    id: gameId
                };

                api.post('api/Game/SetCurrentGame', data, this.continueGameCallback, this.showError)
            },


            continueGameCallback() {
                this.$router.push('/game');

            },

            showError(e) {
                message.error(e);
            }

        },
    });
</script>

<style>

    .poster-row {
        border: 1px solid #f4f4f4;
        margin: 20px;
        border-radius: 10px;
        box-shadow: 0 0 10px #f4f4f4;
        overflow: hidden;
        background-color: #0e0e0e;
    }

    .poster-image {
        height: 150px;
        width: 150px;
        margin-right: 20px;
        margin-bottom: 20px;
        float: left;
    }

 </style>