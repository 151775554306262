<template>
    <a-menu v-model:selectedKeys="currentKeys" mode="horizontal">
        <a-menu-item disabled="false">
                <a-select v-model:value="selectedCity"
                          style="width: 120px"
                          :options="allCities"
                          :field-names="{ label: 'name', value: 'id' }"
                          @change="cityChange" />
        </a-menu-item>
        
        <a-menu-item key="posters">
            <router-link :to="{ name: 'posters' }">
                Афиши
            </router-link>
        </a-menu-item>

        <a-menu-item key="mailings">
            <router-link :to="{ name: 'mailings' }">
                Рассылки
            </router-link>
        </a-menu-item>

        <a-menu-item key="players">
            <router-link :to="{ name: 'players' }">
                Игроки
            </router-link>
        </a-menu-item>

        <a-menu-item key="rating">
            <router-link :to="{ name: 'rating' }">
                Рейтинг
            </router-link>
        </a-menu-item>


    </a-menu>

    <router-view />
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import { api } from './lib/api';
    import { message } from 'ant-design-vue';

    export default defineComponent({
        components: {
            
        },
        data() {
            return {
                username_vue: null,
                userrole: null,
                password_vue: null,
                loginned: false,
                error_info: false,
                currentKeys: null,
                selectedCity: null,
                allCities: null
            };
        },


        mounted() {
            this.currentKeys = [this.$route.name];

            this.getCity();
        },


        methods: {
            getCity() {
                api.get("api/AdminCity/Get",
                    (data) => {
                        this.allCities = data.cities;
                        this.selectedCity = data.currentCityId;
                    },
                    this.showError
                )
            },

            cityChange() {
                if (this.selectedCity == null) return;

                api.post("api/AdminCity/SetCurrent",
                    { id: this.selectedCity },
                    () => {
                        this.$router.go();
                    },
                    this.showError

                )
            },

            showError(e) {
                message.error(e);
            }


        },
    });
</script>

