<template>
    <div>
        LinkPage  {{ $route.params.id }}
    </div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';

    export default defineComponent({
        components: {
            
        },
        data() {
            return {
                username_vue: null,
                userrole: null,
                password_vue: null,
                loginned: false,
                error_info: false
            };
        },

        mounted() {
            localStorage.apiToken = this.$route.params.id;
            this.$router.push('/posters');
        },

        methods: {
          
        },
    });
</script>

