<template>
    <div class="filter-container">
        <a-input-search v-model:value="searchValue" style="width:300px"
                        placeholder="input search text" enter-button
                        @search="refresh" />
        <a-button type="primary" style="float:right;" @click="addUserButton">Добавить игрока</a-button>
    </div>
    <a-table class="ant-table-players"
             :columns="columns"
             :data-source="users"
             :customRow="customrowFunc"
             :row-class-name="(_record) => (_record.isArchived ? 'table-archived' : null)">

        <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'imageId'">
                <div class="data-column">
                    <img v-if="!record.imageId" class="user-avatar" src="../../public/avatar.jpg" />
                    <img v-if="record.imageId" class="user-avatar" :src="getsrc(record.imageId)">
                </div>
            </template>

            <template v-if="column.key === 'nikName'">
                <div class="data-column">
                    {{record.nikName}} / {{record.userName}}
                </div>
            </template>

            <template v-if="column.key === 'cityName'">
                <div v-if="record.localCityName" class="data-column">
                    {{record.localCityName}}
                </div>
                <div v-else class="data-column">
                    {{record.cityName}}
                </div>
            </template>

            <template v-if="column.key === 'loginName'">
                <div v-if="loginName!=''" class="data-column">
                    <a :href="getTelegram(record.loginName)">{{record.loginName}}</a>

                </div>
            </template>

            <template v-if="column.key === 'role'">
                <span v-if="record.role==0">Игрок</span>
                <span v-if="record.role==1">Ведущий</span>
                <span v-if="record.role==2">Админ</span>
                <span v-if="record.role==3">Суперадмин</span>
            </template>

            <template v-if="column.key === 'score'">
                <div class="data-column">
                    {{record.gamesCount}} / {{record.scoreCount}}
                </div>
            </template>

            <template v-if="column.key === 'action'">
                <div v-if="selectedId == record.id">
                    <a-button @click="editUserClick(record)" class="userprofile-table-buttons">Изменить</a-button>
                </div>
            </template>

        </template>
    </a-table>


    <a-modal v-model:visible="userModalVisible"
             :title="modalTitle">



        <template #footer>
            <a-button @click="cancelUserButton">Отмена</a-button>
            <a-button type="primary" @click="saveUserButton">Сохранить</a-button>
        </template>

        <UserProfileCard v-model:value="userValue"/>
    </a-modal>


</template>

<script lang="js">
    import UserProfileCard from '@/admin/UserProfileCard.vue';
    import { defineComponent } from 'vue';
    import { message } from 'ant-design-vue';
    import { api } from '../lib/api.js'
    import dayjs from 'dayjs';
    export default defineComponent({
        components: {
            UserProfileCard
        },
        data() {
            return {
                username_vue: null,
                userrole: null,
                password_vue: null,
                loginned: false,
                error_info: false,

                users: null,
                posterCurrent: null,
                userModalVisible: false,

                fileList: [],
                headers: {},
                actionurl: null,
                selectedId: null,

                showAll: false,
                modalTitle: null,

                searchValue: '',

                userValue: null
            };
        },


        setup() {

            return {
               
                columns: [
                    {
                        title: '',
                        dataIndex: 'imageId',
                        width: 70,
                        key: 'imageId',
                    },




                    {
                        title: 'Ник',
                        dataIndex: 'nikName',
                        key: 'nikName',
                    },
                    {
                        title: 'Город',
                        dataIndex: 'cityName',
                        key: 'cityName',
                    },
                    {
                        title: 'Роль',
                        dataIndex: 'role',
                        key: 'role',
                    },
                    {
                        title: 'Телефон',
                        dataIndex: 'phone',
                        key: 'phone',
                    },
                    {
                        title: 'Телеграмм',
                        dataIndex: 'loginName',
                        key: 'loginName',
                    },

                    {
                        title: 'Игры/очки',
                        dataIndex: '',
                        width: 200,
                        key: 'score',
                    },


                    {
                        title: '',
                        dataIndex: '',
                        width: 150,
                        key: 'action',
                    },


                ],

            };
        },


        mounted() {
            this.headers = {
                authorization: 'Bearer ' + localStorage.apiToken
            };
            this.actionurl = process.env.VUE_APP_BASE_URL + 'api/Image/Add';
            this.refresh();

        },





        methods: {

            addUserButton() {
                this.userValue = {
                    id:0,
                    nikName: "",
                    userName: "",
                    role:0
                };
                this.userModalVisible = true;
                this.modalTitle = "Новый игрок";
            },

            editUserClick(record) {
                this.userValue = JSON.parse(JSON.stringify(record));
                this.userModalVisible = true;
                this.modalTitle = record.nikName+' / '+record.userName;
            },

            saveUserButton() {
                this.userModalVisible = false;
                api.post("api/AdminUsers/Update",
                   this.userValue,
                   () => {
                       
                       this.refresh();
                        
                   },
                   this.showError
                )

            },

            cancelUserButton() {
                this.userModalVisible = false;
            },

            getTelegram(v) {
                return "https://t.me/" + v;
            },

            refresh() {

                api.post("api/AdminUsers/Get",
                    {
                        search: this.searchValue,
                    },
                        (data) => {
                            this.users = data;
                        },
                        this.showError
                    )
               
            },

            getAllusers() {
                this.showAll = true;
                this.refresh();
            },


            hideAllusers() {
                this.showAll = false;
                this.refresh();
            },


            handleChange(info) {
                if (info.file.status === 'done') {
                    message.success(`Фото загружено ${info.file.name}`);
                    console.log(info);
                    //this.posterCurrent.imageId = info.file.response;
                } else if (info.file.status === 'error') {
                    message.error(`Ошибка при загрузке фото ${info.file.name}`);
                    console.log(info);
                }
            },
        



            getsrc(img) {
                if (img)
                    return process.env.VUE_APP_BASE_URL + 'api/Image/Thumbnail?imageId=' + img;
                return null;
            },

            getdate(dt) {
                if (dt == null) return '';
                return dayjs(dt).format('DD.MM.YYYY');

            },


            customrowFunc(record) {

                return {
                    onClick: () => {

                        this.selectedId = record.id;
                    },
                };
            },


            showError(e) {
                message.error(e);
            }



        },
    });
</script>

<style>
    .filter-container {
        margin: 20px 0;
    }

    .userprofile-table-buttons {
        width: 100%;
    }
  
</style>