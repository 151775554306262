import { createApp } from 'vue'
import Antd from 'ant-design-vue';
import { createRouter, createWebHistory } from 'vue-router';

import App from './App.vue';
import AdminPage from './AdminPage.vue';
import GamePage from './GamePage.vue';
import HomePage from './HomePage.vue';

import LoginPage from './admin/LoginPage.vue';
import PostersPage from './admin/PostersPage.vue';
import PlayersPage from './admin/PlayersPage.vue';
import RatingPage from './admin/RatingPage.vue';
import MailingsPage from './admin/MailingsPage.vue';


import GameLoginPage from './game/LoginPage.vue';
import GamePostersPage from './game/GamePostersPage.vue';
import GameAddGamersPage from './game/AddPlayersPage.vue';

import UserRatingPage from './UserRatingPage.vue';

import 'ant-design-vue/dist/antd.dark.css';

const router = createRouter({
    routes: [
        {
            path: "/",
            name: 'home',
            component: HomePage,
        },
        {
            path: '/rating/:id',
            name: 'userrating',
            component: UserRatingPage
        },
        {
            path: "/admin",
            name: 'admin',
            component: AdminPage,
            children: [
                {
                    path: 'login/:id',
                    name: 'link',
                    component: LoginPage
                },
                {
                    path: 'posters',
                    name: 'posters',
                    component: PostersPage
                },
                {
                    path: 'players',
                    name: 'players',
                    component: PlayersPage
                },
                {
                    path: 'rating',
                    name: 'rating',
                    component: RatingPage
                },
                {
                    path: 'mailings',
                    name: 'mailings',
                    component: MailingsPage
                }
            ]
        },
        {
            path: "/game",
            name: 'game',
            component: GamePage,
            children: [
                {
                    path: 'gamers',
                    name: 'gamegamers',
                    component: GameAddGamersPage
                },
            ]
        },
        {
            path: '/posters',
            name: 'gameposters',
            component: GamePostersPage
        },
        {
            path: "/gamelogin/:id",
            name: 'gamelogin',
            component: GameLoginPage,
        },
        {
            path: "/login/:id",
            name: 'login',
            component: LoginPage,
        },
        {
            path: "/*",
            component: HomePage,
        },
    ],
    history: createWebHistory()
})

const app = createApp(App);
app.use(Antd);
app.use(router);
app.mount('#app');



