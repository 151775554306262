<template>
    <div class="maf-container">
        <!--<div class="maf-main-menu">
            <router-link :to="{ name: 'gameposters' }">
                Выбор игры
            </router-link>

            <router-link :to="{ name: 'admin' }">
                Админка
            </router-link>


            <a @click="login">logintest</a>
        </div>-->

        <div class="maf-main-content">
            <router-view />
        </div>
    </div>
</template>

<script>

    import { message } from 'ant-design-vue';
    import { api } from './lib/api.js'
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'App',

        created() {
            document.title = "Mafia"
        },

        methods: {

            login() {
                
                api.unauthorizedGet('api/Account/Test', this.loginCallback, this.showError);
                
            },


            loginCallback(result) {
                console.log('.token',result.token);
                localStorage.apiToken = result.token;
                this.$router.push('/posters');
            },

            showError(e) {
                message.error(e);
            }

        },



    });
</script>


<style>


    #app {
        padding: 20px;
    }

    .footer-buttons {
        position: fixed;
        bottom: 20px;
        right: 20px;
    }

    .user-avatar {
        height: 32px;
        width: 32px;
        border-radius: 50%;
    }

   

</style>