<template>
    <div>
        <div v-if="!voiceMode">
            Выберите игрока, который будет представляться
        </div>
        <h2 v-if="gameValue.activePlayer">
            Представляется {{gameValue.activePlayer.nikName}}
        </h2>

        <div v-if="readytostart">
            Представление игроков завершено
        </div>
    </div>

    <div class="introduce-buttons">
        <a-button v-if="gameValue.activePlayer && voiceMode" type="primary" @click="nextIntroduce">Следующий игрок</a-button>
    </div>

    <div v-if="backVoiceVisible" class="back_voice_button">
        <a-button @click="backVoice">Предыдущий игрок</a-button>
    </div>

    <PlayersTable v-model:game="gameValue" @onPlayerClick="onPlayerClick" @onRefresh="refreshPlayers" />




    <div class="footer-buttons">
        <a-button @click="prevGameStatus">Назад</a-button>
        <a-button :disabled="!finished" type="primary" @click="nextGameStatus">Начать игру</a-button>
    </div>

</template>


<script lang="js">
    import { message } from 'ant-design-vue';
    import { defineComponent } from 'vue';
    import { api } from '../lib/api.js'
    import dayjs from 'dayjs';
    import PlayersTable from '@/game/modules/PlayersTable.vue'

    export default defineComponent({
        components: {
            PlayersTable
        },
        data() {
            return {
                posters: null,
                currentposter: null,
                openModal: false,
                password: null,
                direction: null,
                readytostart: false,

                finished: false,

                voiceMode: false,
                voiceAvailable: [],
                positiveDirectionPlayerId: null,
                negativeDirectionPlayerId: null,
                backVoiceVisible: false

            };
        },


        props: ['game'],
        emits: ['update:game'],

        computed: {
            gameValue: {
                get() {
                    return this.game
                },
                set(value) {
                    this.$emit('update:game', value)
                }
            },
        },


        mounted() {
            this.refreshPlayers();
        },


        methods: {
            refreshPlayers() {
                api.get("api/Game/GetGameInfo",
                    (data) => {
                        this.gameValue = data;
                        this.getIntoduce();
                    },
                    this.showError
                )
            },

            getIntoduce() {
                api.get("api/GameIntroduce/Get",
                    (data) => {
                        this.gameValue.blockScreen = false;
                        this.backVoiceVisible = data.backVoice;

                        this.voiceAvailable = data.nextVoicePlayerVoiceIds;
                        this.positiveDirectionPlayerId = data.positiveDirectionPlayerId;
                        this.negativeDirectionPlayerId = data.negativeDirectionPlayerId;

                        this.finished = (this.voiceAvailable.length == 0);

                        if (this.voiceAvailable.length > 1)
                            this.selectVoicedPlayerMode();
                        else if (this.voiceAvailable.length == 1) {
                            this.gameValue.activePlayer = this.gameValue.players.find(p => p.id == this.voiceAvailable[0]);
                            this.voiceMode = true;
                            if (this.gameValue.activePlayer != null)
                                this.gameValue.currentTable = Math.floor((this.gameValue.activePlayer.orderNum - 1) / 14);
                        }
                    },
                    this.showError
                )
            },

            selectVoicedPlayerMode() {
                this.voiceMode = false;

                for (var i = 0; i < this.gameValue.players.length; i++) {
                    if (!this.voiceAvailable.includes(this.gameValue.players[i].id))
                        this.gameValue.players[i].disabled = true;
                }
            },



            nextIntroduce() {
                if (this.gameValue.activePlayer == null) {
                    return;
                }

                var targetId = null;
                if (this.gameValue.targetPlayer)
                    targetId = this.gameValue.targetPlayer.id;

                var direction = 0;
                if (this.gameValue.activePlayer.id == this.negativeDirectionPlayerId)
                    direction = -1;


                if (this.gameValue.activePlayer.id == this.positiveDirectionPlayerId)
                    direction = 1;

                this.gameValue.blockScreen = true;
                api.post("api/GameIntroduce/Next",
                    {
                        playerId: this.gameValue.activePlayer.id,
                        targetPlayerId: targetId,
                        direction: direction
                    },
                    () => {
                        this.refreshPlayers();

                    },
                    this.showError
                );


            },

            backVoice() {
                this.gameValue.blockScreen = true;
                api.post("api/GameIntroduce/Prev",
                    null,
                    () => {
                        this.refreshPlayers();

                    },
                    this.showError
                );


            },

            getsrc(img) {
                if (img)
                    return process.env.VUE_APP_BASE_URL + 'api/Image/Thumbnail?imageId=' + img;
                return null;
            }, 

            getdate(dt) {
                if (dt == null) return '';
                return dayjs(dt).format('DD.MM.YYYY');

            },

            nextGameStatus() {
                this.gameValue.blockScreen = true;
                api.post("api/Game/SetNextStatus",
                    null,
                    (data) => { this.gameValue = data; },
                    this.showError
                )
            },

            prevGameStatus() {
                this.gameValue.blockScreen = true;
                api.post("api/Game/SetPrevStatus",
                    null,
                    (data) => { this.gameValue = data; },
                    this.showError
                )
            },

            showError(e) {
                message.error(e);
            },

            onPlayerClick(rec) {
                if (!this.voiceMode) {
                    this.gameValue.activePlayer = rec;
                    this.voiceMode = true;
                }
            },


        },
    });
</script>

