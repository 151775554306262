<template>
    <div>
        
    </div>
   
</template>

<script lang="js">
    import { defineComponent } from 'vue';

    export default defineComponent({
        components: {
            
        },
        data() {
            return {
                username_vue: null,
                userrole: null,
                password_vue: null,
                loginned: false,
                error_info: false
            };
        },
        

        methods: {
          

        },
    });
</script>

