<template>

    

    <div class="roles-info">
        Выберите игрока и назначьте ему роль
    </div>

    <div class="roles-counter">
        <h3>Город</h3>
        <div v-for="role in citizenroles" :key="role.id">{{role.name}} - {{role.playersCount}}</div>
        <br>
        <h3>Мафия</h3>
        <div v-for="role in mafiaroles" :key="role.id">{{role.name}} - {{role.playersCount}}</div>
    </div>

    <PlayersTable v-model:game="gameValue" @onPlayerClick="onPlayerClick"  @onRefresh="refreshPlayers"/>

    <div class="footer-buttons">
        <a-button @click="prevGameStatus">Назад</a-button>
        <a-button @click="nextGameStatus">Продолжить</a-button>
    </div>


    <a-modal class="pay-modal" v-model:visible="openRoleModal" :title="titleRoleModal" :footer="null">

        <a-row>
            <a-col :span="12">
                <a-list :data-source="citizenroles">
                    <template #renderItem="{ item }">
                        <a-list-item @click="roleItemClick(item)"><div style="width:100%">{{ item.name }}</div></a-list-item>
                    </template>
                </a-list>
            </a-col>

            <a-col :span="12">
                <a-list :data-source="mafiaroles">
                    <template #renderItem="{ item }">
                        <a-list-item @click="roleItemClick(item)"><div style="width:100%">{{ item.name }}</div></a-list-item>
                    </template>
                </a-list>
            </a-col>
        </a-row>

            
</a-modal>

</template>


<script lang="js">
    import { message } from 'ant-design-vue';
    import { defineComponent } from 'vue';
    import { api } from '@/lib/api.js';
    import PlayersTable from '@/game/modules/PlayersTable.vue'

    export default defineComponent({
        components: {
            PlayersTable
        },
        data() {
            return {
                posters: null,
                currentposter: null,
                openModal: false,
                password: null,
                openRoleModal: false,
                titleRoleModal: null,
                selectedPlayer: null
            };
        },


        props: ['game'],
        emits: ['update:game'],

        computed: {
            gameValue: {
                get() {
                    return this.game
                },
                set(value) {
                    this.$emit('update:game', value)
                }
            },

            mafiaroles: {
                get() {
                    var arr = [];
                    if (this.gameValue == null || this.gameValue.playRoles==null) return [];
                    for (var i = 0; i < this.gameValue.playRoles.length; i++) {
                        var role = this.gameValue.playRoles[i];
                        if (!role.mafiaTeam) continue;
                        role.playersCount = this.gameValue.players.filter((player) => player.playRoleCode == role.code).length;
                        arr.push(role);
                    }
                    return arr;
                },
                set() {
                }
            },

            citizenroles: {
                get() {
                    var arr = [];
                    if (this.gameValue == null || this.gameValue.playRoles == null) return [];
                    for (var i = 0; i < this.gameValue.playRoles.length; i++) {
                        var role = this.gameValue.playRoles[i];
                        if (role.mafiaTeam) continue;
                        role.playersCount = this.gameValue.players.filter((player) => player.playRoleCode == role.code).length;
                        arr.push(role);
                    }
                    return arr;
                },
                set() {
                }
            },
        },


        mounted() {
            this.gameValue.showRolesSwitch = true;
        },


        methods: {
            refreshPlayers() {
                api.get("api/Game/GetGameInfo",
                    (data) => {
                        this.gameValue = data;
                        this.gameValue.currentTable = 0;
                    },
                    this.showError
                )
            },

            calcDeg(num) {
                return (num-1) * 360/14;

            },


            nextGameStatus() {
                this.gameValue.blockScreen = true;
                api.post("api/Game/SetNextStatus",
                    null,
                    (data) => { this.gameValue = data; },
                    this.showError
                )
            },

            prevGameStatus() {
                this.gameValue.blockScreen = true;
                api.post("api/Game/SetPrevStatus",
                    null,
                    (data) => { this.gameValue = data; },
                    this.showError
                )
            },


            showError(e) {
                message.error(e);
            },

            onPlayerClick(rec) {
                this.titleRoleModal = 'Выберите роль для ' + rec.nikName;
                this.selectedPlayer = rec;
                this.openRoleModal = true;
            },

            roleItemClick(item) {
                if (!this.selectedPlayer) return;
                this.saveRoleLoading = true;
                this.gameValue.blockScreen = true;
                api.post("api/GamePlayers/SetRolePlayer",
                    {
                        playerId: this.selectedPlayer.id,
                        playRoleCode: item.code
                    },
                    () => {
                        this.gameValue.blockScreen = false;
                        this.openRoleModal = false;
                        this.saveRoleLoading = false;
                        this.selectedPlayer.playRoleCode = item.code;
                        this.selectedPlayer.playRoleName = item.name;
                        this.selectedPlayer.mafiaTeam = item.mafiaTeam;
                       
                    },
                    this.showError
                )
        
            }
        },
    });
</script>



<style>
    .roles-counter {
        width: 150px;
        float: right;
    }


    

</style>