<template>
    <div v-if="userinfo!=null" class="rating-user-blank">

        <a-row>
            <a-col :span="8">
                <div>
                    <img v-if="!userinfo.imageId" class="rating-user-avatarimg" src="../public/avatar.jpg" />
                    <img v-if="userinfo.imageId" class="rating-user-avatarimg" :src="getsrc(userinfo.imageId)">
                </div>
            </a-col>
            <a-col :span="16">
                <div class="rating-user-info">
                    <div>{{userinfo.userName}}</div>
                    <div>{{userinfo.nikName}}</div>
                    <div>{{userinfo.phone}}</div>
                    <div>{{userinfo.loginName}}</div>
                </div>
            </a-col>
        </a-row>

        



        {{userinfo}}
    </div>
</template>

<script lang="js">
    import { message } from 'ant-design-vue';
    import { defineComponent } from 'vue';
    import { api } from './lib/api.js';

    export default defineComponent({
        components: {
        },
        data() {
            return {
                userinfo: null,
            };
        },


        mounted() {
            api.get('api/UserInfo/GetByGuid/'+this.$route.params.id, 
                (data)=>{this.userinfo = data;}, 
                this.showError);
        },


        methods: {

            getsrc(img) {
                if (img)
                    return process.env.VUE_APP_BASE_URL + 'api/Image/Get?imageId=' + img;
                return null;
            },

            gameinfoCallback(result) {
                this.userinfo = result;
            },

            showError(e) {
                message.error(e);
            }


        },
    });
</script>

<style>
    .rating-user-avatarimg {
        width: 100%;
        margin: 20px 10px 10px 20px;
        border-radius: 10px;
        border: 3px solid white;
        float:left;
    }

    .rating-user-info {
        
        margin: 20px 10px 10px 20px;
        font-size: 120%;

    }

    .rating-user-blank {
        border-radius: 10px;
        border: 1px solid gray;
        background: #313131;
    }

</style>
