<template>
    <div class="fouls-table">

        <a-table class="ant-table-players-fouls"
                 :columns="columns"
                 :data-source="gameValue.players"
                 :scroll="{ y: 400 }" :pagination="false">

            <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'avatar'">
                    <img v-if="!record.imageId" class="user-avatar" src="../../../public/avatar.jpg" />
                    <img v-if="record.imageId" class="user-avatar" :src="getsrc(record.imageId)">
                </template>

                <template v-if="column.key === 'foulsCount'">
                    <span v-for="index in record.foulsCount" :key="index"><ExclamationCircleFilled style="font-size: 18px; color: #ffff8d; padding-right: 5px" /> </span>
                </template>

                <template v-if="column.key === 'actionFouls'">
                    <a-popconfirm v-if="!record.isDead" title="Вы уверены？" ok-text="Да" cancel-text="Нет" @confirm="playerFoulClick(record)">
                        <a-button>Фол</a-button>
                    </a-popconfirm>
                    
                </template>

                <template v-if="column.key === 'actionTechDead'">

                    <a-popconfirm v-if="!record.isDead" title="Вы уверены？" ok-text="Да" cancel-text="Нет" @confirm="techDeadClick(record)">
                        <a-button>Технический труп</a-button>
                    </a-popconfirm>
                    <span v-if="record.isDead">
                        Умер
                    </span>
                    <span v-if="record.isTechDead">
                        (Технический труп)
                    </span>
                </template>

            </template>
        </a-table>
       
    </div>
</template>


<script lang="js">
    import { defineComponent } from 'vue';
    import { api } from '@/lib/api.js'
    import dayjs from 'dayjs';
    import { ExclamationCircleFilled } from '@ant-design/icons-vue';

    export default defineComponent({
        components: { ExclamationCircleFilled
        },
        data() {
            return {
                posters: null,
                currentposter: null,
                openModal: false,
                password: null,
                forceWinVisible: false
            };
        },

        props: ['game'],
        emits: ['update:game', 'onRefresh'],

        computed: {
            gameValue: {
                get() {
                    return this.game
                },
                set(value) {
                    this.$emit('update:game', value)
                }
            },

        },


         setup() {

     return {
         columns: [
             {
                 title: '',
                 dataIndex: 'id',
                 key: 'avatar',
                 width: 50
             },
             {
                 title: '',
                 dataIndex: 'nikName',
                 key: 'nikName',
             },
             {
                title: '',
                dataIndex: '',
                key: 'foulsCount',
             },
             {
                 title: '',
                 dataIndex: '',
                 key: 'actionFouls',
             },

             {
                title: '',
                dataIndex: '',
                key: 'actionTechDead',
                width: 250

             },

         ],

         

     };
 },


        mounted() {
            this.gameValue.currentTable = 0;

        },


        methods: {

            isActiveUser(rec) {
                if (this.gameValue.activePlayer == null) return false;
                return (rec.id == this.gameValue.activePlayer.id);
            },

            calcDeg(num) {
                return (num-1) * 360/14;

            },

            getsrc(img) {
                if (img)
                    return process.env.VUE_APP_BASE_URL + 'api/Image/Thumbnail?imageId=' + img;
                return null;
            }, 

            getdate(dt) {
                if (dt == null) return '';
                return dayjs(dt).format('DD.MM.YYYY');

            },

           
            playerFoulClick(rec) {
                this.gameValue.blockScreen = true;

                api.post("api/GameFouls/SetFoul",
                    {
                        id: rec.id
                    },
                    (data) => {
                        this.gameValue.blockScreen = false;

                        rec.foulsCount = data.foulsCount;
                        rec.isTechDead = data.isTechDead;
                        rec.isDead = data.isDead;
                        rec.canVoice = data.canVoice;
                    },
                    (e) => {
                        this.showError(e);
                    }
                )

            },

            techDeadClick(rec) {
                this.gameValue.blockScreen = true;

                api.post("api/GameFouls/SetTechDead",
                    {
                        id: rec.id
                    },
                    (data) => {
                        

                        rec.isTechDead = data.isTechDead;
                        rec.isDead = data.isDead;
                        rec.canVoice = data.canVoice;
                        this.checkFinish();

                    },
                    (e) => {
                        this.showError(e);
                    }
                )

            },

            checkFinish() {

                api.post("api/Game/CheckFinish",
                    null,
                    () => {
                        this.$emit('onRefresh');
                        this.gameValue.blockScreen = false;
                    },
                    this.showError
                )

            },

        },
    });
</script>

<style>

    .ant-table-players-fouls .ant-table-header {
        display: none;
    }

    

</style>