<template>
    <a-button type="primary" style="float:right; margin:20px;" @click="addMailingButton">Новая рассылка</a-button>

    <a-table class="ant-table-players"
             :columns="columns"
             :data-source="mailings"
             
             :customRow="customrowFunc">

        <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'mailingimg'">
                <img v-if="!record.imageId" class="mailing-img" src="../../public/img.png" />
                <img v-if="record.imageId" class="mailing-img" :src="getsrc(record.imageId)">
            </template>
            <template v-if="column.key === 'sendInfo'">

                <div v-if="record.reciveDate">
                    <div>{{getdate(record.reciveDate)}} - {{record.userName}}</div>
                    <div>Получатели ({{record.reciversCount}}): </div>
                    <div>{{record.recievers}}</div>
                </div>
            </template>
            <template v-if="column.key === 'action'">
                <div v-if="selectedId == record.id">
                    <a-button v-if="!record.reciveDate" @click="sendMailingClick(record)" class="admin-table-buttons">Отправить</a-button>
                    <a-button v-if="record.reciveDate" @click="retryMailingClick(record)" class="admin-table-buttons">Повторить</a-button>
                    
                    <a-popconfirm placement="top" ok-text="Да" cancel-text="Нет" @confirm="deleteMailingClick(record)">
                        <template #title>
                            <p>Удалить рассылку?</p>
                        </template>
                        <a-button class="admin-table-buttons">Удалить</a-button>
                    </a-popconfirm>
                   
                </div>
            </template>



        </template>
    </a-table>


    <a-modal v-model:visible="mailingModalVisible"
             title="Рассылка">



        <template #footer>
            <a-button style="width:140px" @click="cancelMailingButton">Отмена</a-button>
            <a-button style="width:140px" @click="saveMailingButton">Сохранить</a-button>
            <a-button style="width:140px" @click="testMailingButton">Тестовая рассылка</a-button>
            <br>
            <br>

            <a-popconfirm placement="top" ok-text="Да" cancel-text="Нет" @confirm="sendMailingButton">
                <template #title>
                    <p>Отправить рассылку всем пользователям?</p>
                </template>
                <a-button style="width:140px"  type="primary" :disabled="mailingCurrent.reciveDate">Разослать всем</a-button>
            </a-popconfirm>

            <a-popconfirm placement="top" ok-text="Да" cancel-text="Нет" @confirm="sendMailingRuButton">
                <template #title>
                    <p>Отправить рассылку всем пользователям с русским языком?</p>
                </template>
                <a-button style="width:140px" type="primary" :disabled="mailingCurrent.reciveDate">Разослать для RU</a-button>
            </a-popconfirm>

            <a-popconfirm placement="top" ok-text="Да" cancel-text="Нет" @confirm="sendMailingEnButton">
                <template #title>
                    <p>Отправить рассылку всем пользователям с английским?</p>
                </template>
                <a-button style="width:140px" type="primary" :disabled="mailingCurrent.reciveDate">Разослать для EN</a-button>
            </a-popconfirm>


        </template>
        <a-row>
            <a-col :span="12">


                <div style="margin-bottom:20px">
                    <a-upload v-model:file-list="fileList"
                              name="file"
                              :action="actionurl"
                              :headers="headers"
                              @change="handleChange"
                              :capture="null"
                              :max-count="1"
                              :show-upload-list="false">
                        <a-button style="width:150px">
                            <upload-outlined />
                            Фото/видео
                        </a-button>
                    </a-upload>
                </div>

                <div v-if="imageProgress" class="image-loader-box"><a-spin/>{{imageProgress}}%</div>
                <div v-else>
                    <img v-if="!mailingCurrent.imageId" class="mailing-img" src="../../public/img.png" />
                    <img v-if="mailingCurrent.imageId && !mailingCurrent.isVideo" class="mailing-img" :src="getsrc(mailingCurrent.imageId)">
                    <div v-if="mailingCurrent.imageId && mailingCurrent.isVideo" class="video-label"><VideoCameraOutlined /></div>
                </div>
            </a-col>
            <a-col :span="12">
                <a-input style="margin-bottom: 20px" v-model:value="mailingCurrent.title"
                         placeholder="Заголовок"/>
                <a-textarea v-model:value="mailingCurrent.message"
                            placeholder="Введите сообщение"
                            :auto-size="{ minRows: 6, maxRows: 15 }" />
            </a-col>
        </a-row>
        
    </a-modal>

</template>

<script lang="js">
    import { UploadOutlined, VideoCameraOutlined } from '@ant-design/icons-vue';
    import { defineComponent } from 'vue';
    import { message } from 'ant-design-vue';
    import { api } from '../lib/api.js'
    import dayjs from 'dayjs';
    export default defineComponent({
        components: {
            UploadOutlined, VideoCameraOutlined
        },
        data() {
            return {
                username_vue: null,
                userrole: null,
                password_vue: null,
                loginned: false,
                error_info: false,

                mailings: null,
                mailingCurrent: null,
                mailingModalVisible: false,

                fileList: [],
                headers: {},
                actionurl: null,
                selectedId: null,
                imageProgress: false,
                showVideoLabel: false
            };
        },


        setup() {

            return {
                columns: [
                    {
                        title: '',
                        dataIndex: 'imageid',
                        width: 200,
                        key: 'mailingimg',
                    },
                    {
                        title: 'Заголовок',
                        dataIndex: 'title',
                        width: 200,
                        key: 'title',
                    },
                    {
                        title: 'Сообщение',
                        dataIndex: 'message',
                        key: 'message',
                    },

                    {
                        title: 'Отправлено',
                        dataIndex: 'sendInfo',
                        key: 'sendInfo',
                    },

                    {
                        title: '',
                        dataIndex: '',
                        width: 200,
                        key: 'action',
                    },

                   
                ],

              


            };
        },


        mounted() {
            this.headers = {
                authorization: 'Bearer ' + localStorage.apiToken
            };
            this.actionurl = process.env.VUE_APP_BASE_URL + 'api/Image/AddMedia';
            this.refresh();
            
        },

        



        methods: {

            refresh() {
                api.get("api/AdminMailing/Get",
                    (data) => {
                        this.mailings = data;
                    },
                    this.showError
                )
            },

            handleChange(info) {
                if (info.file.status === 'done') {
                    message.success(`Успешно загружен файл ${info.file.name}`);
                    this.mailingCurrent.imageId = info.file.response.imageGuid;
                    this.mailingCurrent.isVideo = info.file.response.isVideo;
                    this.imageProgress = false;
                    api.get('api/Image/IsVideo?imageId=' + this.mailingCurrent.imageId,
                        (data) => this.showVideoLabel = data!=null && data.isVideo)

                } else if (info.file.status === 'error') {
                    message.error(`Ошибка при загрузке фото ${info.file.name}`);
                    this.imageProgress = false;
                } else {
                    if (info.event != null && info.event.type == "progress") {
                        this.imageProgress = Math.round(info.event.percent);
                    }
                   
                }
            },


            cancelMailingButton() {
                this.mailingModalVisible = false;
            },



            deleteMailingClick(rec) {
                api.post("api/AdminMailing/Delete",
                    rec,
                    () => {
                        this.refresh();
                    },
                    this.showError
                )
            },


            saveMailingButton() {
                //this.mailingModalVisible = false;
                message.info('Рассылка сохранена');
                api.post("api/AdminMailing/Save",
                    this.mailingCurrent,
                    (data) => {
                        this.mailingCurrent = data;
                        this.refresh();
                    },
                    this.showError
                )
            },

            sendMailingButton() {
                this.mailingModalVisible = false;

                api.post("api/AdminMailing/Run",
                    this.mailingCurrent,
                    () => {
                        this.refresh();
                    },
                    this.showError
                )
            },

            sendMailingRuButton() {
                this.mailingModalVisible = false;

                api.post("api/AdminMailing/RunRu",
                    this.mailingCurrent,
                    () => {
                        this.refresh();
                    },
                    this.showError
                )
            },

            sendMailingEnButton() {
                this.mailingModalVisible = false;

                api.post("api/AdminMailing/RunEn",
                    this.mailingCurrent,
                    () => {
                        this.refresh();
                    },
                    this.showError
                )
            },

            testMailingButton() {
                api.post("api/AdminMailing/RunTest",
                    this.mailingCurrent,
                    (data) => {
                        this.mailingCurrent = data;
                        this.refresh();
                    },
                    this.showError
                )
            },


            sendMailingClick(rec) {
                this.mailingModalVisible = true;
                this.mailingCurrent = rec;
            },

            retryMailingClick(rec) {
                this.mailingModalVisible = true;
                this.mailingCurrent = {
                    id: 0,
                    title: rec.title,
                    message: rec.message,
                    imageId: rec.imageId,

                }  
            },

            addMailingButton() {

                this.mailingModalVisible = true;
                this.mailingCurrent = 
                {
                    id: 0,
                    title: '',
                    message: '',
                    imageId: null,

                }   
            },

      

            getsrc(img) {
                if (img)
                    return process.env.VUE_APP_BASE_URL + 'api/Image/Thumbnail?imageId=' + img;
                return null;
            },

            getsrcIsVideo(img) {
                if (img)
                    return process.env.VUE_APP_BASE_URL + 'api/Image/IsVideo?imageId=' + img;
                return null;
            },

            getdate(dt) {
                if (dt == null) return '';
                return dayjs(dt).format('DD.MM.YYYY HH:mm:ss');

            },


            customrowFunc(record) {

                return {
                    onClick: () => {
                        console.log(record.id);
                        this.selectedId = record.id;
                    },
                };
            },


            showError(e) {
                message.error(e);
            }



        },
    });
</script>

<style>

    .ant-popover-buttons .ant-btn {
        width: 70px;
    }

    .mailing-img {
        width: 150px;
        height: 150px;
    }

    .image-loader-box {
        color: gray;
        font-size: 120%;
        margin-top: 45px;
        margin-left: 45px;
    }

    .video-label {
        font-size: 80px;
        background-color: #00000075;
        color: #388ed3;
        width: 150px;
        height: 150px;
        text-align: center;
        padding-top: 10px;
    }

</style>