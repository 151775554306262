<template>
    <div>
        День {{gameValue.currentTurn}}
    </div>

    <div>
        <div v-if="!voiceMode">
            Выберите игрока, который будет голосовать
        </div>
        <div v-if="gameValue.activePlayer && voiceMode">
            <h2>Голосует {{gameValue.activePlayer.nikName}}</h2>
        </div>

    </div>

    <div>
        <a-button v-if="gameValue.activePlayer && voiceMode" type="primary" @click="onPlayerPassClick">Пропустить ход</a-button>
        <a-button v-if="gameValue.activePlayer && !voiceMode" type="primary" @click="startVoice">Голосовать</a-button> <br><br>


    </div>

    <div>
        <a-button v-if="gameValue.activePlayer && voiceMode && voiceAvailable.length>1" type="primary" @click="cancelVoice">Отмена</a-button>
    </div>


    <div v-if="backVoiceVisible" class="back_voice_button">
        <a-button @click="backVoice">Отменить голос</a-button>
    </div>




    <PlayersTable v-model:game="gameValue" @onPlayerClick="onPlayerClick" @onRefresh="refreshPlayers" />


    <a-modal v-model:visible="finished" title="Голосование завершено"
             @cancel="nextGameStatus">
        <template #footer>
            <a-button key="submit" type="primary" @click="nextGameStatus">ОК</a-button>
        </template>
        <div v-if="resultPlayers.length==0">Жертв нет</div>
        <div v-for="result in resultPlayers" :key="result.playerId">{{result.nikName}} - {{result.resultMsg}}</div>
    </a-modal>

    <a-modal v-model:visible="confirmvisible"
             title="Голосование"
             ok-text="ОК"
             cancel-text="Отмена"
             @ok="confirmOk">
        <div style="text-align:center;">
            <p style="font-size: 120%" v-if="gameValue.activePlayer"> {{gameValue.activePlayer.nikName}}</p>
            <p style="color: #979797;" v-if="gameValue.targetPlayer">голосует против</p>
            <p style="font-size: 120%" v-if="gameValue.targetPlayer">{{gameValue.targetPlayer.nikName}}</p>
            <p v-if="!gameValue.targetPlayer"> пропускает ход</p>
        </div>
    </a-modal>


    <a-modal v-model:visible="isDuel"
             title="Дуель" :closable="false" :maskClosable="false">
        <template #footer>
        </template>
        <span v-if="gameValue.activePlayer"> {{gameValue.activePlayer.nikName}}</span> выбирает цифру

        <p><a-button v-for="n in duelMaxNumber" :key="n" class="duel_num_button" @click="duelFire(n)" :disabled="checkduelnumenabled(n)">{{ n }} </a-button></p>
    </a-modal>



</template>


<script lang="js">
    import { defineComponent } from 'vue';
    import { message } from 'ant-design-vue';
    import { api } from '../lib/api.js'
    import dayjs from 'dayjs';
    import PlayersTable from '@/game/modules/PlayersTable.vue';


    export default defineComponent({
        components: {
            PlayersTable
        },
        data() {
            return {
                posters: null,
                currentposter: null,
                openModal: false,
                password: null,
                direction: null,
                confirmvisible: false,
                finished: false,
                isDuel: false,
                resultPlayers: null,
                duelPlayers: null,
                duelMaxNumber: null,
                duelNumFired: null,
                voiceMode: false,
                voiceAvailable: [],
                positiveDirectionPlayerId: null,
                negativeDirectionPlayerId: null,
                backVoiceVisible: false
            };
        },

        props: ['game'],
        emits: ['update:game'],

        computed: {
            gameValue: {
                get() {
                    return this.game
                },
                set(value) {
                    this.$emit('update:game', value)
                }
            },
        },

        mounted() {
            this.refreshPlayers();
        },


        methods: {

            selectVoicedPlayerMode() {
                this.voiceMode = false;

                for (var i = 0; i < this.gameValue.players.length; i++) {
                    if (!this.voiceAvailable.includes(this.gameValue.players[i].id))
                        this.gameValue.players[i].disabled = true;
                }
            },
            

            refreshPlayers() {
               
                
                this.gameValue.activePlayer = null;
                for (var i = 0; i < this.gameValue.players.length; i++)
                    this.gameValue.players[i].voiced = false;

                api.get("api/GameDay/GetPlayersState",
                    (data) => {
                        this.gameValue.blockScreen = false;
                        this.isDuel = data.isDuel;
                        this.finished = data.isFinished;
                        this.resultPlayers = data.resultPlayers;
                        this.duelPlayers = data.duelPlayers;
                        this.gameValue.players = data.players;
                        this.gameValue.gameStatus = data.gameStatus;
                        this.gameValue.winnerTeam = data.winnerTeam;
                        this.duelMaxNumber = data.duelMaxNumber;
                        this.duelNumFired = data.duelNumFired;

                        this.backVoiceVisible = data.backVoice;

                        this.voiceAvailable = data.nextVoicePlayerVoiceIds;
                        this.positiveDirectionPlayerId = data.positiveDirectionPlayerId;
                        this.negativeDirectionPlayerId = data.negativeDirectionPlayerId;


                        if (!this.finished && !data.isDuel) {
                            if (this.voiceAvailable.length > 1)
                                this.selectVoicedPlayerMode();
                            else if (this.voiceAvailable.length == 1) {
                                this.gameValue.activePlayer = this.gameValue.players.find(p => p.id == this.voiceAvailable[0]);
                                this.voiceMode = true;
                                if (this.gameValue.activePlayer!=null)
                                    this.gameValue.currentTable = Math.floor((this.gameValue.activePlayer.orderNum - 1) / 14);
                            }
                        }

                        if (!this.finished && data.isDuel) {
                            for (var i = 0; i < this.gameValue.players.length; i++) {
                                if (this.gameValue.players[i].id == data.currentDuelPlayerId)
                                    this.gameValue.activePlayer = this.gameValue.players[i];
                            }
                        }


                    },
                    this.showError
                )
            },

            checkduelnumenabled(n) {
                if (this.duelNumFired == null) return false;
                if (this.duelNumFired.includes(n)) return true;
                return false;
            },


            startVoice() {
                this.voiceMode = true;

                for (var i = 0; i < this.gameValue.players.length; i++) {
                    this.gameValue.players[i].disabled = false;
                }
            },


            duelFire(n) {
                this.gameValue.blockScreen = true;
                api.post("api/GameDay/DuelFire",
                    {
                        playerId: this.gameValue.activePlayer.id,
                        number: n
                    },
                    () => {
                        
                        this.refreshPlayers();

                    },
                    this.showError
                )
            },


            getsrc(img) {
                if (img)
                    return process.env.VUE_APP_BASE_URL + 'api/Image/Thumbnail?imageId=' + img;
                return null;
            }, 

            getdate(dt) {
                if (dt == null) return '';
                return dayjs(dt).format('DD.MM.YYYY');

            },


            onPlayerClick(rec) {
                if (!this.voiceMode) {
                    this.gameValue.activePlayer = rec;
                    this.voiceMode = true;

                    for (var i = 0; i < this.gameValue.players.length; i++) {
                        this.gameValue.players[i].disabled = false;
                    }
                }
                else {
                    if (this.gameValue.activePlayer == rec) return;

                    if (rec.initialImmunity && this.gameValue.currentTurn == 1) {
                        message.error('Иммунитет');
                        return;
                    }
                    this.gameValue.targetPlayer = rec;
                    this.confirmvisible = true;
                }

            },

            cancelVoice() {

                this.refreshPlayers();
            },

            backVoice() {
                this.gameValue.blockScreen = true;
                api.post("api/GameDay/BackVoice",
                    null,
                    () => {
                        this.refreshPlayers();

                    },
                    this.showError
                );


            },

            confirmOk() {
                this.confirmvisible = false;

                if (this.gameValue.activePlayer == null) {
                    return;
                }

                var targetId = null;
                if (this.gameValue.targetPlayer)
                    targetId = this.gameValue.targetPlayer.id;

                var direction = 0;
                if (this.gameValue.activePlayer.id == this.negativeDirectionPlayerId)
                    direction = -1;
                
                    
               if (this.gameValue.activePlayer.id == this.positiveDirectionPlayerId)    
                    direction = 1;

                this.gameValue.blockScreen = true;
                api.post("api/GameDay/Voice",
                    {
                        playerId: this.gameValue.activePlayer.id,
                        targetPlayerId: targetId,
                        direction: direction
                    },
                    () => {
                        this.refreshPlayers();
                        
                    },
                    this.showError
                );
                
               
                
            },



            onPlayerPassClick() {
                this.gameValue.targetPlayer = null;
                this.confirmvisible = true;
            },



            nextGameStatus() {
                this.gameValue.blockScreen = true;
                api.post("api/Game/SetNextStatus",
                    null,
                    (data) => { this.gameValue = data; },
                    this.showError
                )
            },


            showError(e) {
                message.error(e);
            },
        },
    });
</script>


<style>
    .duel_num_button {
        width:50px;
        height: 50px;
        margin: 10px;
        font-size: 200%;
    }

    .back_voice_button {
        position: absolute;
        right: 60px;
        top: 150px;
    }
</style>

