<template>
    <h3>Порядок игроков</h3>

    <a-row>
        <a-col :span="12">
            <a-spin v-if="sortSaveLoading"/> 
                <div v-if="!sortSaveLoading">
                    <a-button v-if="!sortPlayerNum" type="primary" @click="startSorter">Начать сортировку</a-button>
                    <span v-if="sortPlayerNum">
                        Какой игрок ходит {{sortPlayerNum}}
                        <a-button type="primary" @click="cancelSorter">Отменить сортировку</a-button>
                    </span>
                </div>
        </a-col>

    </a-row>


    <a-row>

    </a-row>

    <a-row>
        <a-col >

            <a-table class="ant-table-players"
                     :columns="ingamecolumns"
                     :data-source="gameValue.players"
                     :scroll="{ y: 400 }" :pagination="false"
                     :customRow="customrowFunc">

                <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'avatar'">
                        <img v-if="!record.imageId" class="user-avatar" src="../../public/avatar.jpg" />
                        <img v-if="record.imageId" class="user-avatar" :src="getsrc(record.imageId)">
                    </template>
                    <template v-if="column.key === 'orderNum' && sortPlayerNum">
                        {{record.newOrderNum}}
                    </template>

                    <template v-if="column.key === 'orderNum' && !sortPlayerNum">
                        {{record.orderNum}}
                    </template>

                    <template v-if="column.key === 'gamesCount'">
                        {{record.gameCount}}/{{record.score}}

                    </template>

                    <template v-if="column.key === 'immunity'">
                        <a-switch v-model:checked="record.initialImmunity" @change="changeImmunity(record)" />
                    </template>

                    <template v-if="column.key === 'paid'">
                        <a-spin v-if="loadingPayPlayer" />
                        <div v-if="!loadingPayPlayer">
                            <a-button  :type="getPaidMainStatus(record)" @click="payPlayerClick(record)">{{getPaidInfo(record)}}</a-button>
                        </div>
                    </template>

                    <template v-if="column.key === 'action'">
                        <div v-if="selectedPlayerId==record.id">
                            <a-spin v-if="loadingRemovePlayer" />
                            <a-button v-if="!loadingRemovePlayer" @click="removePlayerClick(record.id)">Убрать</a-button>
                        </div>
                    </template>

                </template>
            </a-table>
        </a-col>
        
    </a-row>

    <div>

    </div>




    <div class="footer-buttons">
        <a-button @click="prevGameStatus">Назад</a-button>
        <a-button @click="nextGameStatus">Продолжить</a-button>
    </div>


    <a-modal class="pay-modal" v-model:visible="openPayModal" :title="titlePayModal" @ok="payPlayerOk">
        <a-row class="pay-modal-row">
            <a-col :span="24">
                <a-radio-group v-model:value="selectedPayStatus" button-style="solid" @change="payStatusChange">
                    <a-radio-button value="0">Отсутствует</a-radio-button>
                    <a-radio-button value="1">Оплачено</a-radio-button>
                    <a-radio-button value="2">Оплатил другой</a-radio-button>
                    <a-radio-button value="3">Бесплатно</a-radio-button>
                </a-radio-group>
            </a-col>
        </a-row>

        <a-row v-if="paySumVisible()" class="pay-modal-row">
            <a-col :span="12">
                <a-radio-group v-model:value="selectedPaySum" button-style="solid">
                    <a-radio-button v-if="gameValue.prepayment" :value="gameValue.prepayment">{{gameValue.prepayment}}</a-radio-button>
                    <a-radio-button :value="gameValue.payment">{{gameValue.payment}}</a-radio-button>
                </a-radio-group>
            </a-col>
            <a-col :span="12">
                Другая сумма <a-input-number v-model:value="selectedPaySum" :min="1" :max="100000" />
            </a-col>
        </a-row>

        <a-row v-if="payOtherPlayerVisible()" class="pay-modal-row">
            <a-col :span="24">
                Оплатил игрок
                <a-select ref="select"
                          v-model:value="selectedPaidPlayer"
                          style="width: 120px"
                          :options="otherPayPlayers"
                          :field-names="{ label: 'nikName', value: 'id', options: 'children' }"
                          @change="payOtherPlayerChange"/>
            </a-col>
        </a-row>

    </a-modal>

</template>


<script lang="js">
    import { message } from 'ant-design-vue';
    import { defineComponent } from 'vue';
    import { api } from '../lib/api.js'
    import dayjs from 'dayjs';

    export default defineComponent({
        components: {
        },
        data() {
            return {
                modalAddNew: false,
                newPlayerName: '',
                newPlayerNik: '',
                loadingNewPlayer: false,
                loadingRemovePlayer: false,
                loadingSavePayModal: false,

                searchloading: false,
                availablePlayers: null,
                loadingAddPlayer: false,
                loadingPayPlayer: false,

                selectedPlayerId: null,

                timerCount: 0,

                openPayModal: false,
                titlePayModal: null,

                selectedPayStatus:0,
                selectedPaySum:0,
                selectedPayRec: null,

                otherPayPlayers: null,

                selectedPaidPlayer: null,

                sortPlayerNum: null,
                sortSaveLoading: false
            };
        },


        setup() {

            return {
                

                ingamecolumns: [
                    {
                        title: 'Порядок',
                        dataIndex: 'orderNum',
                        key: 'orderNum',
                    },
                    {
                        title: '',
                        dataIndex: 'id',
                        key: 'avatar',
                    },
                    {
                        title: 'Ник',
                        dataIndex: 'nikName',
                        key: 'nikName',
                    },
                    {
                        title: 'Имя',
                        dataIndex: 'userName',
                        key: 'userName',
                    },
                    {
                        title: 'Игр/очков',
                        dataIndex: '',
                        key: 'gamesCount',
                    },
                    {
                        title: 'Оплата',
                        dataIndex: '',
                        key: 'paid',
                    },
                    {
                        title: 'Иммунитет',
                        dataIndex: '',
                        key: 'immunity',
                    },
                    {
                        title: '',
                        dataIndex: '',
                        key: 'action',
                    },


                ],


            };
        },


        watch: {
            timerCount: {
                handler() {
                    {
                        setTimeout(() => {
                            if (!this.gameValue.DisableUpldate) {
                                api.get('api/Game/GetGameInfo',
                                    (data) => {
                                        this.gameValue = data;
                                    },
                                    this.showError);
                            }
                            this.timerCount++;
                        }, 30000);
                    }

                },
                immediate: true // This ensures the watcher is triggered upon creation
            }
        },


        props: ['game'],
        emits: ['update:game'],

        computed: {
            gameValue: {
                get() {
                    return this.game
                },
                set(value) {
                    this.$emit('update:game', value)
                }
            },


        },



        mounted() {
        },

        unmounted() {
            
        },


        methods: {

            paySumVisible() {
                return this.selectedPayStatus == 1 || this.selectedPayStatus == 2
            },
           
            payOtherPlayerVisible() {
                return this.selectedPayStatus == 2
            },

            usernamechanged() {
                
            },

            startSorter() {
                this.sortPlayerNum = 1;
                this.gameValue.DisableUpldate = true;
                var len = this.gameValue.players.length;
                for (var i = 0; i < len; i++) {
                    this.gameValue.players[i].newOrderNum = null;
                }

            },

            cancelSorter() {
                this.sortPlayerNum = null;
            },


           


            getPaidInfo(record) {
                if (record.payStatus == 0) return "Оплата";
                if (record.payStatus == 1) return record.paySum + '';
                if (record.payStatus == 2) return "Игрок: " + record.paidPlayerName + " " + record.paySum;
                if (record.payStatus == 3) return "Бесплатно";
            },

            removePlayerClick(playerId) {
                this.loadingRemovePlayer = true;
                this.gameValue.blockScreen = true;
                api.post("api/GamePlayers/RemovePlayer",
                    {
                        id: playerId
                    },
                    () => {
                        this.gameValue.blockScreen = false;
                        this.gameValue.players = this.gameValue.players.filter((player) => player.id != playerId);
                        this.loadingRemovePlayer = false;
                    },
                    (e) => {
                        this.loadingRemovePlayer = false;
                        this.showError(e);
                    }
                )
            },


            prevGameStatus() {
                this.gameValue.blockScreen = true;
                api.post("api/Game/SetPrevStatus",
                    null,
                    (data) => { this.gameValue = data; },
                    this.showError
                )
            },

            nextGameStatus() {
                this.gameValue.blockScreen = true;
                api.post("api/Game/SetNextStatus",
                    null,
                    (data) => { this.gameValue = data; },
                    this.showError
                )
            },


            getPaidMainStatus(record) {
                if (record.payStatus == 0) return "primary";
                return "";
            },

            payPlayerClick(rec) {
                this.titlePayModal = "Оплата от "+rec.nikName;
                this.selectedPayStatus = rec.payStatus+'';
                this.selectedPaySum = rec.paySum+'';
                this.selectedPayRec = rec;

                this.otherPayPlayers = this.gameValue.players.filter(f => f.paySum != 0 && f.payStatus == 1);

                console.log(this.otherPayPlayers);
                
                this.openPayModal=true;
            },


            payPlayerOk() {
                this.selectedPayRec.paySum = this.selectedPaySum;
                this.selectedPayRec.payStatus = this.selectedPayStatus;
                this.openPayModal=false;
                this.gameValue.blockScreen = true;
                api.post("api/GamePlayers/SavePaidPlayer",
                    {
                        playerId: this.selectedPayRec.id,
                        paySum: this.selectedPaySum,
                        payStatus: this.selectedPayStatus,
                        paidPlayerId: this.selectedPaidPlayer
                    },
                    (data) => {
                        this.gameValue.blockScreen = false;
                        this.selectedPayRec.paySum = data.paySum;
                        this.selectedPayRec.payStatus = data.payStatus;
                    },

                    this.showError
                );
                
            },


            payStatusChange() {
                console.log(this.selectedPayStatus);
            },


            payOtherPlayerChange() {
                console.log('payOtherPlayerChange');
            },

            changeImmunity(record) {
                this.gameValue.blockScreen = true;
                 api.post("api/GamePlayers/SaveImmunityPlayer",
                    {
                         playerId:  record.id,
                         initialImmunity: record.initialImmunity
                    },
                     (data) => {
                         this.gameValue.blockScreen = false;
                         record.initialImmunity = data.initialImmunity;
                    },

                    this.showError
                );
            },

            saveOrder() {
                this.sortSaveLoading = true;
                this.gameValue.blockScreen = true;
                api.post("api/GamePlayers/SaveOrderPlayers",
                    this.gameValue.players,
                    (data) => {
                        this.gameValue.blockScreen = false;
                        this.gameValue.players = data;
                        this.sortPlayerNum = null;
                        this.sortSaveLoading = false;
                    },

                    this.showError
                );
            },


            customrowFunc(record) {

                return {
                    onClick: () => {
                        this.selectedPlayerId = record.id;

                        if (this.sortPlayerNum && record.newOrderNum==null) {
                            record.newOrderNum = this.sortPlayerNum;
                            if (this.sortPlayerNum >= this.gameValue.players.length) {
                                this.saveOrder();
                            }
                            else
                            this.sortPlayerNum++;
                            
                        }
                    },
                };
            },


            getsrc(img) {
                if (img)
                    return process.env.VUE_APP_BASE_URL + 'api/Image/Thumbnail?imageId=' + img;
                return null;
            },

            getdate(dt) {
                if (dt == null) return '';
                return dayjs(dt).format('DD.MM.YYYY');

            },


            





            showError(e) {
                message.error(e);
            }
        },
    });
</script>


<style>

    .pay-modal-row {
        margin-bottom: 30px;
    }

    .player-row {
        background: #666;
        margin: 10px;
        padding: 10px;
        height: 50px;
        border-radius: 25px;
        max-width: 300px;
    }

    .user-avatar {
        height: 32px;
        width: 32px;
        border-radius: 16px;
    }


    .pay-modal {
        padding: 20px;
    }
</style>