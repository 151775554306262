<template>
    <div class="date-range-container">
        <a-range-picker v-model:value="dateRange" :locale="locale" @change="dateRangeChange" />

        <a-button @click="CurrentMonthClick">Текущий месяц</a-button>
    </div>
    <a-table class="ant-table-players"
             :columns="columns"
             :data-source="users"
             :customRow="customrowFunc"
             :row-class-name="(_record) => (_record.isArchived ? 'table-archived' : null)">

        <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'imageId'">
                <div class="data-column">
                    <img v-if="!record.imageId" class="user-avatar" src="../../public/avatar.jpg" />
                    <img v-if="record.imageId" class="user-avatar" :src="getsrc(record.imageId)">
                </div>
            </template>
            <template v-if="column.key === 'nikName'">
                <div class="data-column">
                    {{record.nikName}}
                </div>
            </template>

            <template v-if="column.key === 'gamesCount'">
                <div class="data-column">
                    {{record.gamesCount}} / {{record.winGamesCount}}
                </div>
            </template>

            <template v-if="column.key === 'peacefulGames'">
                <div class="data-column">
                    {{record.peacefulGames}} / {{record.peacefulGamesWin}}
                </div>
            </template>

            <template v-if="column.key === 'mafiaGames'">
                <div class="data-column">
                    {{record.mafiaGames}} / {{record.mafiaGamesWin}}
                </div>
            </template>

        </template>
    </a-table>





</template>

<script lang="js">
    import locale from 'ant-design-vue/es/date-picker/locale/ru_RU';
    import { defineComponent } from 'vue';
    import { message } from 'ant-design-vue';
    import { api } from '../lib/api.js'
    import dayjs from 'dayjs';
    export default defineComponent({
        components: {

        },
        data() {
            return {
                username_vue: null,
                userrole: null,
                password_vue: null,
                loginned: false,
                error_info: false,

                users: null,
                posterCurrent: null,
                posterModalVisible: false,

                selectedId: null,
                playersModalVisible: false,
                currentPlayers: null,

                showAll: false,
                dateRange: null
            };
        },


        setup() {

            return {
                locale,

                columns: [
                    {
                        title: '',
                        dataIndex: 'imageId',
                        width: 70,
                        key: 'imageId',
                    },
                    {
                        title: 'Ник',
                        dataIndex: 'nikName',
                        key: 'nikName',
                    },
                    {
                        title: 'Игр (всего/выиграно)',
                        dataIndex: 'gamesCount',
                        key: 'gamesCount',
                    },
                    {
                        title: 'За мафию (всего/выиграно)',
                        dataIndex: 'mafiaGames',
                        key: 'mafiaGames',
                    },
                    {
                        title: 'За мирных (всего/выиграно)',
                        dataIndex: 'peacefulGames',
                        key: 'peacefulGames',
                    },
                    {
                        title: 'Набрано очков',
                        dataIndex: 'scoreCount',
                        key: 'scoreCount',
                    },

                ],


            };
        },


        mounted() {
            this.CurrentMonthClick();
            this.refresh();

        },





        methods: {
            CurrentMonthClick() {
                let date = new Date();
                let monthStart = date.setDate(1);
                let currentMonth = date.getMonth();
                let nextMonth = ++currentMonth;
                let nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);
                let oneDay = 1000 * 60 * 60 * 24;

                this.dateRange = [
                    dayjs(monthStart),
                    dayjs(nextMonthFirstDay - oneDay)
                ];
                this.refresh();
            },

            dateRangeChange() {
                this.refresh();
            },

            refresh() {
                if (this.dateRange == null) return;


                api.post("api/Rating/Get",
                    {
                        beginDate: this.dateRange[0].format('YYYY-MM-DD')+"T00:00:00Z",
                        endDate: this.dateRange[1].format('YYYY-MM-DD')+"T00:00:00Z"
                    },
                    (data) => {
                        this.users = data;
                    },
                    this.showError
                )

            },
       



            getsrc(img) {
                if (img)
                    return process.env.VUE_APP_BASE_URL + 'api/Image/Thumbnail?imageId=' + img;
                return null;
            },

            getdate(dt) {
                if (dt == null) return '';
                return dayjs(dt).format('DD.MM.YYYY');

            },


            customrowFunc(record) {

                return {
                    onClick: () => {

                        this.selectedId = record.id;
                    },
                };
            },


            showError(e) {
                message.error(e);
            }



        },
    });
</script>

<style>

    .date-range-container {
        margin: 20px 0;
    }
  
</style>