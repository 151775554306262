<template>
    <a-row>
        <a-col :span="12">
            В Игре
        </a-col>
        <a-col :span="12">
            <div style="margin-left:10px;">
                Добавить игрока
            </div>
        </a-col>
    </a-row>

    <a-row>
        <a-col :span="12"> </a-col>

        <a-col style="padding-left: 10px" :span="4">
            <span>Ник:</span>
        </a-col>
        <a-col style="padding-left: 10px" :span="4">
            <span>Введите имя:</span>
        </a-col>
    </a-row>

    <a-row>
        <a-col :span="12">
            <a-spin v-if="loadingPreviousPlayers" />
            <a-button v-if="gameValue.userGameNum > 1 && !loadingPreviousPlayers" type="primary" @click="AddPreviousPlayers">Скопировать игроков из предыдущей игры</a-button>
        </a-col>
        <a-col style="padding-left: 10px" :span="4">
            <a-input v-model:value="newPlayerName" @change="playerNameChanged" />
        </a-col>
        <a-col style="padding-left: 10px" :span="4">
            <a-input v-model:value="newPlayerNik" @change="playerNameChanged" />
        </a-col>
        <a-col style="padding-left: 10px" :span="4">
            <a-spin v-if="loadingNewPlayer" />
            <a-button v-if="!loadingNewPlayer" type="primary" @click="newPlayerClick">Новый игрок</a-button>
        </a-col>
    </a-row>


    <a-row>
        <a-col :span="12"></a-col>
        <a-col :span="12">
            <div style="margin-left: 10px; height: 30px">
                <a-spin v-if="searchloading" />
            </div>
        </a-col>
    </a-row>

    <a-row>
        <a-col :span="12">

            <a-table class="ant-table-players"
                     :columns="ingamecolumns"
                     :data-source="gameValue.players"
                     :scroll="{ y: 400 }" :pagination="false"
                     :customRow="customrowFunc">

                <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'avatar'">
                        <img v-if="!record.imageId" class="user-avatar" src="../../public/avatar.jpg" />
                        <img v-if="record.imageId" class="user-avatar" :src="getsrc(record.imageId)">
                    </template>
                    <template v-if="column.key === 'gamesCount'">
                        {{record.gameCount}}/{{record.score}}
                    </template>
                    <template v-if="column.key === 'action'">
                        <div v-if="selectedPlayerId==record.id">
                            <a-spin v-if="loadingRemovePlayer" />
                            <a-button v-if="!loadingRemovePlayer" @click="removePlayerClick(record.id)">Убрать</a-button>
                        </div>
                    </template>

                </template>
            </a-table>
        </a-col>
        <a-col :span="12">
            <div style="margin-left:10px;">
                <a-table class="ant-table-players"
                         :columns="columns"
                         :data-source="availablePlayers"
                         :scroll="{ y: 400 }" :pagination="false">

                    <template #bodyCell="{ column, record }">
                        <template v-if="column.key === 'avatar'">
                            <img v-if="!record.imageId" class="user-avatar" src="../../public/avatar.jpg" />
                            <img v-if="record.imageId" class="user-avatar" :src="getsrc(record.imageId)">
                        </template>
                        <template v-if="column.key === 'gamesCount'">
                            {{record.gameCount}}/{{record.score}}
                        </template>

                        <template v-if="column.key === 'action'">
                            <a-spin v-if="loadingAddPlayer==record.userProfileId" />
                            <a-button v-if="loadingAddPlayer!=record.userProfileId" @click="addPlayerClick(record.userProfileId)">В игру</a-button>
                        </template>

                    </template>
                </a-table>
            </div>

        </a-col>
    </a-row>

    <div>

    </div>




    <div class="footer-buttons">
        <a-button @click="nextGameStatus">Продолжить</a-button>
    </div>
</template>


<script lang="js">
    import { message } from 'ant-design-vue';
    import { defineComponent } from 'vue';
    import { api } from '../lib/api.js'
    import dayjs from 'dayjs';

    export default defineComponent({
        components: {
        },
        data() {
            return {
                modalAddNew: false,
                newPlayerName: '',
                newPlayerNik: '',
                loadingNewPlayer: false,
                loadingRemovePlayer: false,
                loadingPreviousPlayers: false,

                searchloading: false,
                availablePlayers: null,
                loadingAddPlayer: false,

                selectedPlayerId: null,

                timerCount: 0
            };
        },


        setup() {

            return {
                columns: [
                    {
                        title: '',
                        dataIndex: 'id',
                        key: 'avatar',
                    },
                    {
                        title: 'Ник',
                        dataIndex: 'nikName',
                        key: 'nikName',
                    },
                    {
                        title: 'Имя',
                        dataIndex: 'userName',
                        key: 'userName',
                    },
                    {
                        title: 'Игр/очков',
                        dataIndex: '',
                        key: 'gamesCount',
                    },

                    {
                        title: '',
                        dataIndex: '',
                        key: 'action',
                    },



                ],

                ingamecolumns: [
                    {
                        title: '',
                        dataIndex: 'id',
                        key: 'avatar',
                    },
                    {
                        title: 'Ник',
                        dataIndex: 'nikName',
                        key: 'nikName',
                    },
                    {
                        title: 'Имя',
                        dataIndex: 'userName',
                        key: 'userName',
                    },
                    {
                        title: 'Игр/очков',
                        dataIndex: '',
                        key: 'gamesCount',
                    },
                    {
                        title: '',
                        dataIndex: '',
                        key: 'action',
                    },



                ],


            };
        },


        watch: {
            timerCount: {
                handler() {

                    {
                        setTimeout(() => {
                            if (!this.gameValue.DisableUpldate) {
                                api.get('api/Game/GetGameInfo',
                                    (data) => {
                                        this.gameValue = data;
                                    },
                                    this.showError);
                            }
                            this.timerCount++;
                        }, 30000);
                    }

                },
                immediate: true // This ensures the watcher is triggered upon creation
            }
        },


        props: ['game'],
        emits: ['update:game'],

        computed: {
            gameValue: {
                get() {
                    return this.game
                },
                set(value) {
                    this.$emit('update:game', value)
                }
            },


        },



        mounted() {
            this.playerNameChanged();
        },

        unmounted() {
        },


        methods: {

            usernamechanged() {
                
            },


            newPlayerClick() {
                this.loadingNewPlayer = true;
                this.gameValue.blockScreen = true;
                api.post("api/GamePlayers/AddNewPlayer",
                    {
                        userName: this.newPlayerName,
                        nikName: this.newPlayerNik
                    },
                    (data) => {
                        this.gameValue.blockScreen = false;
                        this.gameValue.players.push(data);
                        this.loadingNewPlayer = false;
                    },
                    (e) => {
                        this.loadingNewPlayer = false;
                        this.showError(e);
                    }
                )
            },


            AddPreviousPlayers() {
                this.loadingPreviousPlayers = true;
                this.gameValue.blockScreen = true;
                api.post("api/GamePlayers/AddPreviosPlayers",
                    null,
                    (data) => {
                        this.gameValue=data;
                        this.loadingPreviousPlayers = false;
                        this.playerNameChanged();
                    },
                    (e) => {
                        this.loadingPreviousPlayers = false;
                        this.showError(e);
                    }
                )

            },

            addPlayerClick(userId) {
                this.loadingAddPlayer = userId;
                this.gameValue.blockScreen = true;
                api.post("api/GamePlayers/AddPlayer",
                    {
                        id: userId
                    },
                    (data) => {
                        this.gameValue.players.push(data);
                        this.loadingAddPlayer = null;
                        this.playerNameChanged();
                    },
                    (e) => {
                        this.loadingAddPlayer = null;
                        this.showError(e);
                    }
                )
            },


            removePlayerClick(playerId) {
                this.loadingRemovePlayer = true;
                this.gameValue.blockScreen = true;
                api.post("api/GamePlayers/RemovePlayer",
                    {
                        id: playerId
                    },
                    () => {
                        this.gameValue.blockScreen = false;
                        this.gameValue.players = this.gameValue.players.filter((player) => player.id != playerId);
                        this.loadingRemovePlayer = false;
                        this.playerNameChanged();
                    },
                    (e) => {
                        this.loadingRemovePlayer = false;
                        this.showError(e);
                    }
                )
            },


            nextGameStatus() {
                this.gameValue.blockScreen = true;
                api.post("api/Game/SetNextStatus",
                    null,
                    (data) => { this.gameValue = data; },
                    this.showError
                )
            },


            playerNameChanged() {

                this.searchloading = true;

                api.post("api/GamePlayers/FindPlayers",
                    {
                        userName: this.newPlayerName,
                        nikName: this.newPlayerNik
                    },
                    (data) => {
                        this.gameValue.blockScreen = false;
                        this.availablePlayers = data;
                        this.searchloading = false;
                    },
                    (e) => {
                        this.searchloading = false;
                        this.showError(e);
                    }
                );


            },

            customrowFunc(record) {

                return {
                    onClick: () => {
                        this.selectedPlayerId = record.id;
                    },
                };
            },


            getsrc(img) {
                if (img)
                    return process.env.VUE_APP_BASE_URL + 'api/Image/Thumbnail?imageId=' + img;
                return null;
            },

            getdate(dt) {
                if (dt == null) return '';
                return dayjs(dt).format('DD.MM.YYYY');

            },





            showError(e) {
                message.error(e);
            }
        },
    });
</script>


<style>
    .player-row {
        background: #666;
        margin: 10px;
        padding: 10px;
        height: 50px;
        border-radius: 25px;
        max-width: 300px;
    }

    
</style>