<template>
    <h2>
        <span>Поздравляю, игра окончена!</span>
        <span v-if="gameValue.winnerTeam==0"> Ничья</span>
        <span v-if="gameValue.winnerTeam==1"> Победитель - мирные</span>
        <span v-if="gameValue.winnerTeam==2"> Победитель - мафия</span>
    </h2>

    
    <div style="margin: 0 100px">
        <h3 class="result-team-header">Мафия ({{scoreDataMafia.length}})</h3>
        <a-table class="ant-table-players"
                 :columns="columns"
                 :data-source="scoreDataMafia"
                 :pagination="false"
                 :customRow="customrowFunc"
                 size="small">

            <template #bodyCell="{ column, text, record }">
                <template v-if="column.key === 'avatar'">
                    <img v-if="!record.imageId" :class="{'user-avatar': true, 'finish-dead-user': record.isDead }" src="../../public/avatar.jpg" />
                    <img v-if="record.imageId" :class="{'user-avatar': true, 'finish-dead-user': record.isDead }" :src="getsrc(record.imageId)">
                </template>

                <template v-if="column.key === 'nikName' || column.key === 'roleName'">
                    <div :class="{'dead-player-row':record.isDead }">{{text}} </div>
                </template>

                <template v-if="column.key === 'gameScore'">
                    <div class="score-result-button">
                        <a @click="showDetailScore(record)">{{text}}</a>
                    </div>
                </template>

            </template>
        </a-table>

        <h3 class="result-team-header">Мирные ({{scoreDataPeaceful.length}})</h3>
        <a-table class="ant-table-players"
                 :columns="columns"
                 :data-source="scoreDataPeaceful"
                 :pagination="false"
                 :customRow="customrowFunc"
                 size="small">

            <template #bodyCell="{ column, text, record }">
                <template v-if="column.key === 'avatar'">
                    <img v-if="!record.imageId" :class="{'user-avatar': true, 'finish-dead-user': record.isDead }" src="../../public/avatar.jpg" />
                    <img v-if="record.imageId" :class="{'user-avatar': true, 'finish-dead-user': record.isDead }" :src="getsrc(record.imageId)">
                </template>

                <template v-if="column.key === 'nikName' || column.key === 'roleName'">
                    <div :class="{'dead-player-row':record.isDead }">{{text}} </div>
                </template>

                <template v-if="column.key === 'gameScore'">
                    <div class="score-result-button">
                        <a @click="showDetailScore(record)">{{text}}</a>
                    </div>
                </template>

            </template>
        </a-table>

    </div>


    <a-modal v-model:visible="detailedScoreVisible" :title="detailedScoreTitle" :width=800>
        <template #footer>
            <div style="text-align:center">
                Итого: {{selectedPlayer.gameScore}}
            </div>
        </template>

        <a-table class="ant-table-players"
                 :columns="detailedcolumns"
                 :data-source="detailedScore"
                 :scroll="{ y: 400 }" :pagination="false">

            <template #bodyCell="{ column, record }">

                <template v-if="column.key === 'phase'">
                    <span v-if="record.phase==0">День</span>
                    <span v-if="record.phase==1">Ночь</span>
                </template>

                <template v-if="column.key === 'scoreItems'">
                    <div v-for="item in record.scoreItems" :key="item.id">
                        {{item.score}} - {{item.scoreActionName}}
                    </div>
                </template>

            </template>
        </a-table>
    </a-modal>

</template>


<script lang="js">
    import { defineComponent } from 'vue';
    import { api } from '../lib/api.js'
    import dayjs from 'dayjs';
    import { message } from 'ant-design-vue';

    export default defineComponent({
        components: {
        },
        data() {
            return {
                scoreData: null,
                scoreDataMafia: [],
                scoreDataPeaceful: [],
                selectedPlayer: null,
                detailedScore: null,
                detailedScoreVisible: false,
                detailedScoreTitle: null
            };
        },


        props: ['game'],
        emits: ['update:game'],

        computed: {
            gameValue: {
                get() {
                    return this.game
                },
                set(value) {
                    this.$emit('update:game', value)
                }
            },

        },

        setup() {

            return {
                columns: [
                    {
                        title: '',
                        dataIndex: 'id',
                        key: 'avatar',
                        width: 80,
                        align: 'center'
                    },
                    {
                        title: 'Ник',
                        dataIndex: 'nikName',
                        key: 'nikName',
                        width: 250
                    },
                    {
                        title: 'Роль',
                        dataIndex: 'roleName',
                        key: 'roleName',
                        width: 200
                    },
                    {
                        title: 'Очки',
                        dataIndex: 'gameScore',
                        key: 'gameScore',
                        width: 80,
                        align: 'center'

                    },
                    
                ],


                detailedcolumns: [
                    {
                        title: 'Ход',
                        dataIndex: 'turnNum',
                        key: 'turnNum',
                        width: 100
                    },
                    {
                        title: 'Фаза',
                        dataIndex: 'phase',
                        key: 'phase',
                        width: 150
                    },
                    {
                        title: 'Подробно',
                        dataIndex: 'scoreItems',
                        key: 'scoreItems',
                    },

                ],


            };
        },




        mounted() {
            api.post("api/GameFinish/GetAllScories",
                {
                    id: this.gameValue.id
                },
                (data) => {
                    this.scoreDataMafia = data.filter((s)=>s.isMafia);
                    this.scoreDataPeaceful = data.filter((s)=>!s.isMafia);
                },
                this.showError
            )
        },


        methods: {

            getsrc(img) {
                if (img)
                    return process.env.VUE_APP_BASE_URL + 'api/Image/Thumbnail?imageId=' + img;
                return null;
            }, 

            getdate(dt) {
                if (dt == null) return '';
                return dayjs(dt).format('DD.MM.YYYY');

            },


            customrowFunc(record) {

                return {
                    onClick: () => {
                        this.selectedPlayer = record;

                    },
                };
            },

            showDetailScore(record) {

                api.post("api/GameFinish/GetPlayerScories",
                    {
                        id: record.id
                    },
                    (data) => {
                        this.detailedScore = data;
                        this.detailedScoreVisible = true;
                        this.detailedScoreTitle = record.nikName;
                    },
                    this.showError
                );

               


            },

            showError(e) {
                message.error(e);
            },


        },
    });
</script>

<style>

    .finish-dead-user {
        filter: brightness(0.5);
    }


    .dead-player-row {
        color: gray;
    }

    .result-team-header {
        text-align: center;
        margin-top: 20px;
    }


    .score-result-button {
        font-size: 110%;
        font-weight: bold;
    }

    .score-result-button a {
        color: lightgoldenrodyellow

    }


</style>

