<template>
    <div style="float:right">
        <router-link :to="{ name: 'gameposters' }">
            Выбор игры
        </router-link>
    </div>

    <div v-if="gameinfo">
        <h4>

            {{gameinfo.posterInfo}}. {{gameinfo.name}}
        </h4>

        <AddPlayersPage v-if="gameinfo.gameStatus==0" v-model:game="gameinfo" />
        <OrderPlayersPage v-if="gameinfo.gameStatus==1" v-model:game="gameinfo" />
        <SetRolePlayersPage v-if="gameinfo.gameStatus==2" v-model:game="gameinfo" />
        <IntroducePlayersPage v-if="gameinfo.gameStatus==3" v-model:game="gameinfo" />
        <DayGamePage v-if="gameinfo.gameStatus==5" v-model:game="gameinfo" />
        <NightGamePage v-if="gameinfo.gameStatus==8" v-model:game="gameinfo" />
        <FinishedGame v-if="gameinfo.gameStatus==10" v-model:game="gameinfo" />


        <div v-if="gameinfo.blockScreen" class="block-window">
        </div>
    </div>
</template>

<script lang="js">
    import { message } from 'ant-design-vue';
    import { defineComponent } from 'vue';
    import { api } from './lib/api.js'

    import AddPlayersPage from './game/AddPlayersPage.vue';
    import DayGamePage from './game/DayGamePage.vue';
    import FinishedGame from './game/FinishedGame.vue';
    import IntroducePlayersPage from './game/IntroducePlayersPage.vue';
    import NightGamePage from './game/NightGamePage.vue';
    import OrderPlayersPage from './game/OrderPlayersPage.vue';
    import SetRolePlayersPage from './game/SetRolePlayersPage.vue';

    export default defineComponent({
        components: {
            AddPlayersPage,
            DayGamePage,
            FinishedGame,
            IntroducePlayersPage,
            NightGamePage,
            OrderPlayersPage,
            SetRolePlayersPage
        },
        data() {
            return {
                gameinfo: null,
            };
        },


        mounted() {
            api.get('api/Game/GetGameInfo', this.gameinfoCallback, this.showError);
        },


        methods: {

            gameinfoCallback(result) {
                this.gameinfo = result;
            },

            showError(e) {
                message.error(e);
            }


        },
    });
</script>

<style>
    .block-window {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 9999;
        background: rgba(0, 0, 0, 0.5);
    }

    @media screen and (max-width:800px) {
        .maf-main-content {
            zoom: 50%;
        }
        /* put your css style in there */
    }

    @media screen and (min-width:800px) {
        .maf-main-content {
            zoom: 70%;
        }
        /* put your css style in there */
    }

    /* middle size style */
    @media screen and (min-width:1000px) {
        .maf-main-content {
            zoom: 80%;
        }
    }

    /* large size style */
    @media screen and (min-width:1150px) {
        .maf-main-content {
            zoom: 100%;
        }
    }
</style>
