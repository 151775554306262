<template>

    <a-button type="primary" style="float:right; margin:20px;" @click="addPosterButton">Добавить афишу</a-button>

    <a-table class="ant-table-players"
             :columns="columns"
             :data-source="posters"
             :customRow="customrowFunc"
             :row-class-name="(_record) => (_record.isArchived ? 'table-archived' : null)">

        <template #bodyCell="{ column, record, text }">
            <template v-if="column.key === 'Posterimg'">
                <div class="data-column">
                    <img v-if="!record.imageId" class="poster-img" src="../../public/img.png" />
                    <img v-if="record.imageId" class="poster-img" :src="getsrc(record.imageId)">
                </div>
            </template>
            <template v-if="column.key === 'info' || column.key === 'fullInfo'">
                <div class="data-column">
                    {{text}}
                </div>
            </template>
            <template v-if="column.key === 'eventDate'">
                <div class="data-column">
                    {{getdate(record.eventDate)}}
                </div>
            </template>
            <template v-if="column.key === 'gamers'">
                <div class="data-column">
                    <span>{{record.gamersCount}} / {{record.gamersLimit}}</span>
                    <span v-if="selectedId == record.id && record.gamers.length>0">
                        <a @click="showPlayersClick(record)" class="admin-table-buttons">Список</a>
                    </span>
                </div>
            </template>
            <template v-if="column.key === 'action'">
                <div v-if="selectedId == record.id">
                    <a-button @click="editPosterClick(record)" class="admin-table-buttons">Изменить</a-button>
                    <a-popconfirm placement="top" ok-text="Да" cancel-text="Нет" @confirm="deletePosterClick(record)">
                        <template #title>
                            <p>Удалить афишу?</p>
                        </template>
                        <a-button class="admin-table-buttons">Удалить</a-button>
                    </a-popconfirm>

                </div>
            </template>



        </template>
    </a-table>

    <a-button v-if="!showAll" type="primary" style="margin:20px;" @click="getAllPosters">Показать завершенные мероприятия</a-button>

    <a-button v-if="showAll" type="primary" style="margin:20px;" @click="hideAllPosters">Скрыть завершенные мероприятия</a-button>

    <a-modal v-model:visible="playersModalVisible"
             title="Список игроков" :footer="false">

        <a-table class="ant-table-players"
                 :columns="columnsPlayers"
                 :scroll="{ y: 400 }" :pagination="false"
                 :data-source="currentPlayers">

            <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'playerimg'">
                    <img v-if="!record.imageId" class="user-avatar" src="../../public/avatar.jpg" />
                    <img v-if="record.imageId" class="user-avatar" :src="getsrc(record.imageId)">
                </template>
            </template>
        </a-table>


    </a-modal>

    <a-modal v-model:visible="posterModalVisible"
             title="Афиша">



        <template #footer>
            <a-button @click="cancelPosterButton">Отмена</a-button>
            <a-button @click="savePosterButton">Сохранить</a-button>
        </template>
        <a-row>
            <a-col :span="12">


                <div style="margin-bottom:20px">
                    <a-upload v-model:file-list="fileList"
                              name="file"
                              :action="actionurl"
                              :headers="headers"
                              :capture="null"
                              @change="handleChange"
                              :max-count="1"
                              :show-upload-list="false">
                        <a-button style="width:150px">
                            <upload-outlined />
                            Фото
                        </a-button>
                    </a-upload>
                </div>
                <img v-if="!posterCurrent.imageId" class="poster-img" src="../../public/img.png" />
                <img v-if="posterCurrent.imageId" class="poster-img" :src="getsrc(posterCurrent.imageId)">

            </a-col>
            <a-col :span="12">
                <a-input style="margin-bottom: 20px" v-model:value="posterCurrent.info"
                         placeholder="Заголовок" />
                <a-textarea v-model:value="posterCurrent.fullInfo"
                            placeholder="Описание"
                            :auto-size="{ minRows: 5, maxRows: 15 }"
                            style="margin-bottom: 20px" />

                <a-row>
                    <a-col :span="12">
                        <div class="edit-description">Дата</div>
                    </a-col>
                    <a-col :span="12">
                        <a-date-picker v-model:value="posterCurrent.datejs"
                                       style="margin-bottom: 20px; width:100%" />
                    </a-col>
                </a-row>

                <a-row>
                    <a-col :span="12">
                        <div class="edit-description">Лимит игроков</div>
                    </a-col>
                    <a-col :span="12">
                        <a-input-number v-model:value="posterCurrent.gamersLimit" :min="10" :max="99"
                                        style="margin-bottom: 20px; width:100%" />
                    </a-col>
                </a-row>

            </a-col>
        </a-row>

    </a-modal>

</template>

<script lang="js">
    import { UploadOutlined } from '@ant-design/icons-vue';
    import { defineComponent } from 'vue';
    import { message } from 'ant-design-vue';
    import { api } from '../lib/api.js'
    import dayjs from 'dayjs';
    export default defineComponent({
        components: {
            UploadOutlined
        },
        data() {
            return {
                username_vue: null,
                userrole: null,
                password_vue: null,
                loginned: false,
                error_info: false,

                posters: null,
                posterCurrent: null,
                posterModalVisible: false,

                fileList: [],
                headers: {},
                actionurl: null,
                selectedId: null,
                playersModalVisible: false,
                currentPlayers: null,

                showAll: false
            };
        },


        setup() {

            return {
                columnsPlayers: [{
                    title: '',
                    dataIndex: 'imageId',
                    width: 80,
                    key: 'playerimg',
                },

                    {
                        title: 'Игрок',
                        dataIndex: 'userName',
                        key: 'userName',
                    },


                    {
                        title: 'Всего человек',
                        dataIndex: 'gamersCount',
                        key: 'gamersCount',
                    },],



                columns: [
                    {
                        title: '',
                        dataIndex: 'imageId',
                        width: 200,
                        key: 'Posterimg',
                    },

                    {
                        title: 'Дата',
                        dataIndex: 'eventDate',
                        key: 'eventDate',
                    },


                    {
                        title: 'Заголовок',
                        dataIndex: 'info',
                        key: 'info',
                    },
                    {
                        title: 'Описание',
                        dataIndex: 'fullInfo',
                        key: 'fullInfo',
                    },

                    {
                        title: 'Игроки',
                        dataIndex: '',
                        width: 200,
                        key: 'gamers',
                    },


                    {
                        title: '',
                        dataIndex: '',
                        width: 200,
                        key: 'action',
                    },

                   
                ],

              
            };
        },


        mounted() {
            this.headers = {
                authorization: 'Bearer ' + localStorage.apiToken
            };
            this.actionurl = process.env.VUE_APP_BASE_URL + 'api/Image/Add';
            this.refresh();
            
        },

        



        methods: {

            refresh() {
                if (this.showAll) {
                    api.get("api/AdminPoster/GetAll",
                        (data) => {
                            this.posters = data;
                        },
                        this.showError
                    )
                }
                else {
                    api.get("api/AdminPoster/Get",
                        (data) => {
                            this.posters = data;
                        },
                        this.showError
                    )
                }
            },

            getAllPosters() {
                this.showAll = true;
                this.refresh();
            },


            hideAllPosters(){
                this.showAll = false;
                this.refresh();
            },


            handleChange(info) {
                if (info.file.status === 'done') {
                    message.success(`Фото загружено ${info.file.name}`);
                    console.log(info);
                    this.posterCurrent.imageId = info.file.response;
                } else if (info.file.status === 'error') {
                    message.error(`Ошибка при загрузке фото ${info.file.name}`);
                    console.log(info);
                }
            },


            cancelPosterButton() {
                this.posterModalVisible = false;
            },



            deletePosterClick(rec) {
                api.post("api/AdminPoster/Delete",
                    rec,
                    () => {
                        this.refresh();
                    },
                    this.showError
                )
            },


            savePosterButton() {
                this.posterModalVisible = false;
                this.posterCurrent.eventDate = this.posterCurrent.datejs.startOf('Date').format('YYYY-MM-DD');
  
                api.post("api/AdminPoster/Save",
                    this.posterCurrent,
                    (data) => {
                        this.posterCurrent = data;
                        this.refresh();
                    },
                    this.showError
                )
            },

          

            editPosterClick(rec) {
                this.posterModalVisible = true;
                this.posterCurrent = JSON.parse(JSON.stringify(rec));
                this.posterCurrent.datejs = dayjs(this.posterCurrent.eventDate);
            },

           
            addPosterButton() {

                this.posterModalVisible = true;
                this.posterCurrent = 
                {
                    id: 0,
                    info: '',
                    fullInfo: '',
                    imageId: null,
                    gamersLimit: 10,
                    datejs: dayjs()
                }   
            },

            showPlayersClick(rec) {
                this.currentPlayers = rec.gamers;
                this.playersModalVisible = true;
            },


            
      

            getsrc(img) {
                if (img)
                    return process.env.VUE_APP_BASE_URL + 'api/Image/Thumbnail?imageId=' + img;
                return null;
            },

            getdate(dt) {
                if (dt == null) return '';
                return dayjs(dt).format('DD.MM.YYYY');

            },


            customrowFunc(record) {

                return {
                    onClick: () => {
    
                        this.selectedId = record.id;
                    },
                };
            },


            showError(e) {
                message.error(e);
            }



        },
    });
</script>

<style>

    .admin-table-buttons {
        width: 120px;
        margin: 10px;
    }


    .ant-popover-buttons .ant-btn {
        width: 70px;
    }

    .edit-description {
        color: gray;
        margin-top: 5px;
    }

    .table-archived .data-column {
        opacity: 0.3;
    }

    .poster-img {
        width: 150px;
        height: 150px;
    }

</style>