export const api = {
    

    get(url, okFunc, catchFunc) {

        if (!localStorage.apiToken) return;

        var fetchOptions = {
            credentials: 'include',
            headers: {
                authorization: 'Bearer ' + localStorage.apiToken
            }
        };

        fetch(process.env.VUE_APP_BASE_URL + url, fetchOptions)
            .then(async r => {

                if (r.ok) {
                    var json = await r.json();
                    okFunc(json);
                }
                else {

                    var err = await r.text();
                    console.log('ERROR', r.status, err);
                    catchFunc(r.status + ' - ' + err);
                }

            }).catch(r => {
                console.log('ERROR', r.message);
                catchFunc(r.message);
            });

    },


    unauthorizedGet(url, okFunc, catchFunc) {

         fetch(process.env.VUE_APP_BASE_URL + url)
            .then(async r => {

                if (r.ok) {
                    var json = await r.json();
                    okFunc(json);
                }
                else {

                    var err = await r.text();
                    console.log('ERROR', r.status, err);
                    catchFunc(r.status + ' - ' + err);
                }

            }).catch(r => {
                console.log('ERROR', r.message);
                catchFunc(r.message);
            });

    },


    post(url, data, okFunc, catchFunc) {

        if (!localStorage.apiToken) return;

        var fetchOptions = {
            method: "post",
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                authorization: 'Bearer ' + localStorage.apiToken
            },
            body: JSON.stringify(data)
        };

        fetch(process.env.VUE_APP_BASE_URL + url, fetchOptions)
            .then(async r => {
                if (r.ok) {
                    var json = await r.json();
                    okFunc(json);
                }
                else {

                    var err = await r.text();
                    console.log('ERROR', r.status, err);

                    catchFunc(r.status + ' - ' + err);
                }

            }).catch(r => {
                console.log('ERROR', r.message);
                catchFunc(r.message);
            });

    }

} 