<template>
    <div v-if="userValue">

        <a-row style="margin-bottom:20px">
            <a-col :span="8">Ник</a-col>
            <a-col :span="16"><a-input v-model:value="userValue.nikName" /></a-col>
        </a-row>

        <a-row style="margin-bottom:20px">
            <a-col :span="8">Имя</a-col>
            <a-col :span="16"><a-input v-model:value="userValue.userName" /></a-col>
        </a-row>

        <a-row style="margin-bottom:20px">
            <a-col :span="8">Телефон</a-col>
            <a-col :span="16"><a-input v-model:value="userValue.phone" /></a-col>
        </a-row>

        <a-row style="margin-bottom:20px">
            <a-col :span="8">Роль</a-col>
            <a-col :span="16">
                <div style="height:30px;" v-if="userValue.role == 3">Суперадмин</div>
                <div style="height:30px;" v-if="userValue.role == 2 && !allowAdmin">Админ</div>
                <a-select v-else v-model:value="userValue.role"
                          style="width: 100%"
                          :options="userRoles" />
            </a-col>
        </a-row>

        <a-row style="margin-bottom:20px">
            <a-col :span="8">Родной город</a-col>
            <a-col :span="16">
                <a-select v-model:value="userValue.cityId"
                          style="width: 120px"
                          :options="allCities"
                          :field-names="{ label: 'name', value: 'id' }"
                          />
            </a-col>
        </a-row>

        <a-row>
            <a-col :span="8">Текущий город</a-col>
            <a-col :span="16">
                <a-select v-model:value="userValue.localCityId"
                          style="width: 120px"
                          :options="allCities"
                          :field-names="{ label: 'name', value: 'id' }"
                          />
            </a-col>
        </a-row>
    </div>

</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import { api } from '../lib/api.js'
    import { message } from 'ant-design-vue';

    export default defineComponent({
        components: {

        },
        data() {
            return {
                username_vue: null,
                userrole: null,
                password_vue: null,
                loginned: false,
                error_info: false,
                currentKeys: null,
                allCities: null,
                userRoles: null,
                allowAdmin: false
            };
        },

        props: ['value'],
        emits: ['update:value'],

        computed: {
            userValue: {
                get() {
                    this.getCity();
                    return this.value
                },
                set(value) {
                    this.$emit('update:value', value)
                }
            },

            userRoles1: {
                get() {
                    return [{ label: "Игрок", value: 0 }, { label: "Ведущий", value: 1 }, { label: "Админ", value: 2 }]
                },
                set() {}
            }


        },


        mounted() {
            this.currentKeys = [this.$route.name];
            this.getCity();
            

        },


        methods: {
            getCity() {
                api.get("api/AdminCity/GetAll",
                    (data) => {
                        this.allCities = data.cities;
                        if (this.userValue.cityId == null)
                            this.userValue.cityId = data.currentCityId;
                    },
                    this.showError
                )

                api.get("api/AdminUsers/GetRoles",
                    (data) => {
                        this.userRoles = data.map(f => { return { label: f.name, value: f.id } });
                        console.log(this.userRoles, data);
                        if (this.userRoles.length == 3) this.allowAdmin = true;
                    },
                    this.showError
                )
            },

            showError(e) {
                message.error(e);
            }

        },
    });
</script>

